<template>
  <div class="mainVideo-wrapper">
    <MainVideo />
    <About />
    <OurBenefits />
    <OpenAccount />
    <Services />
    <BankSecurity />
    <OurCorporate />
    <FeesAim />
    <ApiGuide />
    <ContactForm />
    <Contact />
  </div>
</template>

<script>
import MainVideo from "./mainVideo.vue";
import About from "./about.vue";
import OurBenefits from "./ourBenefits.vue";
import OpenAccount from "./openAccount.vue";
import Services from "./services.vue";
import BankSecurity from "./bankSecurity.vue";
import OurCorporate from "./ourCorporate.vue";
import FeesAim from "./feesAim.vue";
import ApiGuide from "./apiGuides.vue";
import Contact from "./contact.vue";
import ContactForm from "./ContactForm.vue";

export default {
  components: {
    ContactForm,
    MainVideo,
    About,
    OurBenefits,
    OpenAccount,
    Services,
    BankSecurity,
    OurCorporate,
    ApiGuide,
    FeesAim,
    Contact,
  },
  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mainVideo-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
}
</style>
