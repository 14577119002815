<template>
  <div class="account">
    <div class="account-wrapper">
      <div class="header-wrapper">
        <div class="header">
          <!-- <div
          @click="$router.push('/personal-account')"
          :class="['header-item', {'header-item__active': $route.name === 'home'}]"
        >Home
        </div> -->
          <div
            @click="$router.push('/personal-account/details')"
            :class="[
              'header-item',
              { 'header-item__active': $route.name === 'details' },
            ]"
          >
            Account Details
          </div>
          <div
            @click="$router.push('/personal-account/transactions')"
            :class="[
              'header-item',
              { 'header-item__active': $route.name === 'transactions' },
            ]"
          >
            Transactions
          </div>
          <div
            @click="$router.push('/personal-account/international-transfer')"
            :class="[
              'header-item',
              {
                'header-item__active': $route.name === 'internationalTransfer',
              },
            ]"
          >
            International transfer
          </div>
          <div
            @click="$router.push('/personal-account/intra-transfer')"
            :class="[
              'header-item',
              { 'header-item__active': $route.name === 'intraTransfer' },
            ]"
          >
            Intra transfer
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (!this.$store.getters.user) this.$router.push("/");
  },
};
</script>

<style lang="scss" scoped>
.account {
  background: #d9deff;
}
.account-wrapper {
  max-width: 1230px;
  margin: 0 auto;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;

  @media print {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-item {
  font-family: Montserrat-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  margin-right: 25px;
  text-transform: uppercase;
  cursor: pointer;

  &__active {
    color: #38a1f7;
  }
}

@media screen and (max-width: 1200px) {
  .header-item {
    font-size: 18px;
  }
}
</style>
