<template>
  <div class="bankSecurity" id="section-five">
    <div class="bankSecurity-wrapper">
      <div class="bankSecurity-content">
        <h2 class="title">BANK SECURITY</h2>
        <p class="text">Client AML Policy</p>
        <p class="text">1.PAYMENT TRANSACTIONS</p>
        <p class="text">
          1.1 Client may request Payment Transactions be executed from the
          Payment Account, for and on behalf of Client, to an Individual
          Customer (or to a third party which will receive/hold the funds for
          the benefit of an Individual Customer);
        </p>
        <p class="text">
          1.2 Cleveland Bank shall only carry out its obligations if Client has
          provided the information relevant to the method of payment/recipient;
        </p>
        <p class="text">
          1.3 As part of executing a Payment Transaction, Cleveland Bank may
          include the information provided by Client, as well as any other
          information about Client, the Payment Account and/or the Payment
          Transaction of which it is aware, within any payment instruction
          submitted to a payment system where it is necessary to ensure the
          Payment Transaction is valid. Cleveland Bank shall not accept any
          payment instruction in relation to an Individual Customer until
          adequate information has been provided by Client so that Cleveland
          Bank may satisfy itself of that Individual Customer's identity, and
          carry on any other assessment as required under the AML Requirements,
          in accordance with Client AML Policy below (if this has not already
          been complied with in relation to the Individual Customer).
        </p>
        <p class="text">2. REQUIRED PAYMENT INFORMATION</p>
        <p class="text">
          To make a transfer to a bank account Client must
          provide:
        </p>
        <p class="text">1. Name of the recipient;</p>
        <p class="text">
          2. Identity of Payment Service Provider for the recipient;
        </p>
        <p class="text">3. Currency Payment Transaction is to be made in;</p>
        <p class="text">
          4. Country where account of the recipient is located; and
        </p>
        <p class="text">
          5. The Unique Identifiers of recipient’s account, namely
        </p>
        <p class="text">
          (A) if Client is making a EURO payment into an account in SADR, the
          recipient’s sort code and account number; or
        </p>
        <p class="text">
          (B) if Client is making a non- euro payment into an account outside
          SADR or a SEPA Credit Transfer, the recipient’s IBAN.
        </p>
        <p class="text">3. INDIVIDUAL CUSTOMER DUE DILIGENCE</p>
        <p class="text">
          3.1 Any relevant 'know your client' or other checks required to
          satisfy the AML Requirements must be undertaken on Individual
          Customers by Client before any Payment Transaction to that Individual
          Customer will be made under this Agreement.
        </p>
        <p class="text">
          3.2 Client agrees that it must provide additional documents concerning
          Client, any Individual Customer, or a specific Payment Transaction
          upon Cleveland Bank reasonable request within 5 (five) Business Days.
        </p>
        <p class="text">
          3.3 Client acknowledges that Cleveland Bank may terminate or postpone,
          at any time, the use of login details, access to the Payment Account
          or execution of any Payment Transaction in the absence of any
          sufficient information about the Individual Customer, the nature or
          purpose of a Payment Transaction or the identity of any party relating
          to that Payment Transaction. Such termination or postponement shall be
          notified in advance to Client, provided that such notification does
          not mean that Cleveland Bank is in breach of any Applicable Law.
        </p>
        <p class="text">
          3.4 No legal or civil action may be brought, nor any professional
          sanction pronounced against Cleveland Bank (including its managers or
          employees) where it has:
        </p>
        <p class="text">
          3.4.1 failed to execute any Payment Transaction or carry out any
          instruction of Client because either: a) Client has failed to provide
          the information required under Client AML Policy; or b) it has a
          reasonable suspicion of breach of any AML Requirement or any other
          illegal activity; or
        </p>
        <p class="text">
          3.4.2 reported any suspicion of breach of any AML Requirement or any
          other illegal activity in good faith to any competent regulatory body
          or authority.
        </p>
        <p class="text">
          3.5 The Parties agree that each of them shall be responsible for
          undertaking their own necessary anti-money laundering and counter
          terrorism checks in accordance with AML Requirements. The Parties may
          rely on information shared between them relating to such checks,
          provided that the information is not obviously incorrect, but each
          Party shall remain responsible for its own compliance with AML
          Regulations.
        </p>
        <p class="text" id="section-six">
          3.6 Sometimes legal or regulatory authorities or banking partners
          require additional information, either in respect of organizations or
          particular transactions. Client agrees to supply at any time all such
          reasonable information which Cleveland Bank or any legal or regulatory
          authority or one of its banking partners may require or which
          Cleveland Bank may be required to supply to any legal or regulatory
          authority or any of its banking partners in relation to Client or any
          payment instruction.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.bankSecurity {
  position: relative;
}
.bankSecurity-wrapper {
  max-width: 1170px;
  margin: 100px auto;
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  text-align: left;
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  text-align: left;
}

.bankSecurity-content {
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .text {
    font-size: 18px;
  }
}
</style>
