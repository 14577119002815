<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__content d-flex-rn flex-space-between">
        <div class="footer__logo-block">
          <div class="">
            <img src="..\..\assets\logo.svg" alt="logo" class="logo-img"/>
          </div>
        </div>
        <div class="footer__contact-block">
          <div class="footer__menu-section">
            <ul class="d-flex-rw">
              <li class="footer__text">
                <span v-scroll-to="'#section-one'">About</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-two'">Our benefits</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-three'"
                >How to open an account</span
                >
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-four'">Services</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-five'">Bank security</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-six'">Corporate</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-seven'">Fees</span>
              </li>
              <li class="footer__text">
                <span v-scroll-to="'#section-eight'">Contact</span>
              </li>
            </ul>
          </div>
          <div class="footer__contact-section d-flex-rw">
            <div class="footer__mail-block">
              <ul>
                <li class="footer__text padding-bottom-15">
                  <span>Our mail</span>
                </li>
                <li>
                  <span>
                    <a
                        href="mailto:info@clevelandibu.com"
                        class="footer__text"
                    >info@clevelandibu.com
                      <span class="footer__icon"
                      ><img src="..\..\assets\footer\mail.svg" alt=""
                      /></span>
                    </a>
                  </span>
                </li>
                <li>
                  <span class="footer__text"> GIIN: KFQRGB.99999.SL.732 </span>
                </li>
                <li>
                  <span class="footer__text"> LEI: 254900F9TESLVKN9C396 </span>
                </li>
              </ul>
            </div>
            <div class="footer__tel-block">
              <ul>
                <li class="footer__text padding-bottom-15">
                  <span>Our phone</span>
                </li>
                <li class="footer__text padding-bottom-15">
                  <a href="tel:+9718000321116" class="footer__text"
                  >+ 9718000321116
                    <span class="footer__icon"
                    ><img src="..\..\assets\footer\tel.svg" alt=""
                    /></span>
                  </a>
                </li>
                <li class="padding-bottom-15">
                  <a href="https://wa.me/37257390877" class="footer__text">
                    + 37257390877
                  </a>
                  <span class="footer__icon">
                    <a
                        href="https://wa.me/37257390877"
                        target="_blank"
                    ><img src="..\..\assets\footer\whatsapp.svg" alt=""
                    /></a>
                  </span>
                </li>
                <li class="padding-bottom-15">
                  <a href="https://t.me/ClevelandBank" class="footer__text">
                    @ClevelandBank
                  </a>
                  <span class="footer__icon">
                    <a href="https://t.me/ClevelandBank" target="_blank"
                    ><img src="..\..\assets\footer\telegram.svg" alt=""
                    /></a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer__text-rights-reserved">
            Cleveland Bank © 2023 / All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {};
</script>

<style lang="scss" scoped>
.footer__wrapper {
  max-width: 1920px;
  width: 100%;
}

li {
  display: flex;

  a {
    padding-right: 0px !important;
  }
}

.footer__text {
  padding-right: 21px;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.footer__text-rights-reserved {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  text-align: left;
  padding-left: 60px;
  padding-bottom: 7px;
}

.footer__logo-block {
  min-width: 40%;
  padding: 20px;
}

.footer__contact-block {
  width: 60%;
  background: #4354b0;
}

.footer__icon {
  padding-left: 10px;
}

.footer__menu-section {
  padding: 80px 0px 48px 67px;
}

.footer__registration-section {
  padding: 0px 0px 60px 67px;
}

.footer__contact-section {
  padding: 0px 0px 58px 67px;
}

.footer__mail-block {
  padding-right: 150px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 600px) {
    padding-right: 0px;
  }
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .footer__content {
    flex-direction: column;
    align-items: center;

    .footer__contact-block {
      width: 100%;
    }

    .footer__menu-section,
    .footer__registration-section,
    .footer__text-rights-reserved,
    .footer__contact-section {
      padding-left: 20px;
    }

    .foooter__mail-block {
      padding-right: 0;
    }
  }

  .footer__text {
    font-size: 18px;
  }
}
</style>
