<template>
  <div class="services" id="section-four">
    <div class="services-wrapper">
      <div class="services-content">
        <div class="services-title-block">
          <h2>SERVICES</h2>
        </div>
        <el-tabs :tab-position="tabPosition" style="height: auto">
          <el-tab-pane label="CRYPTO" class="services-crypto">
            <p class="services-text padding-top-115">
              Cryptocurrency is a contemporary form of private money. Thanks to
              its technological property, its global transaction networks are
              relatively safe, transparent, and fast. This gives it good
              prospects for further development. As with other innovations,
              cryptocurrency pose a challenge to financial regulators, in
              particular because of their anonymity and trans-border character.
            </p>
            <p class="services-text">
              There are several different ways to add crypto exposure to your
              investment portfolio. From buying actual cryptocurrency to
              purchasing bitcoin mining stocks or blockchain, there is seemingly
              something for every type of investor.
            </p>
            <p class="services-text">
              If you want to start investing in cryptocurrency, you can purchase
              your first crypto by opening an account in Cleveland Bank.
              Receive, store, send, buy, sell and use cryptocurrencies and fiat
              currency easily, quickly and safely.
            </p>
          </el-tab-pane>
          <el-tab-pane
            label="INTERNATIONAL TRANSFERS"
            class="services-transfer"
          >
            <p class="services-text padding-top-115">
              With Cleveland Bank you can send money overseas anywhere. It
              usually takes up to 3 business days for the payment to reach the
              recipient. Transfers made on a weekend will be processed the
              following business day. Large amount transfers should be arranged
              through your account manager.
            </p>
          </el-tab-pane>
          <el-tab-pane label="CASH DEPOSIT" class="services-cash">
            <p class="services-text padding-top-115">
              Deposit cash into an account through ATM, Western Union or thought
              a branch with a bank representative. For more details please
              contact your account manager.
            </p>
          </el-tab-pane>
          <el-tab-pane label="WESTERN UNION" class="services-union">
            <p class="services-text padding-top-115">
              Western Union, the global money transfer service from one location
              to another, is now at your order at Cleveland Bank. To use the
              advantages offered by Western Union, a global money transfer
              service, you just have to use e-banking of Cleveland Bank and
              transfer money to your relatives in any corner of the world thanks
              to the privileges offered by Western Union.
            </p>
            <p class="services-text">
              Western Union is a fast, reliable and widely popular money
              transfer system that allows you to transfer or receive moneys
              easily. It has a presence in more than 200 countries globally and
              it has been in service for more than 150 years.
            </p>
          </el-tab-pane>
          <el-tab-pane
            label="INTERNATIONAL CHEQUES"
            class="services-international"
          >
            <p class="services-text padding-top-115">
              Check clearance is available in Cleveland Bank for our current
              clients. Full details should be provided to your account manager.
              The process can take till 30 working days.
            </p>
          </el-tab-pane>
          <el-tab-pane
            label="CHEQUE CLEARANCE"
            class="services-cheque padding-top-145"
          >
            <p class="services-text padding-top-115">
              The time of this operation varies from 10 to 40 banking days.
            </p>
          </el-tab-pane>
          <el-tab-pane
            label="CARD PAYMENT PROCESSING"
            class="services-cheque padding-top-145"
          >
            <p class="services-text">
              Card payment processing for businesses Multi-currency and
              multi-jurisdiction payments We accept and settle payments in most
              major currencies allowing you to expand your market and grow your
              business.
            </p>
            <p class="services-text">
              Our APIs make integration easy. If technical support is requested
              additional charges may apply. We support all major payment methods
              including Visa, Mastercard.
            </p>
            <p class="services-text">
              Clients security is our priority. The very best tech and
              authentication tools safeguard your business, secure your
              transactions and protect your customers. Detailed transaction
              report.
            </p>
            <p class="services-text">
              We offer solutions for all types of businesses, including high
              risk ones. Flexible fees that depends on the type of the business
              (MDR 3-5 per cent).
            </p>
            <p class="services-text">
              Services Available 24/7 <br> Your account manager will be always ready
              to assist you. Rolling reserve of around 100 000 EUR for each
              currency withheld for a period of 6 months depending on the
              business model and volume processed. Chargebacks & disputes Every
              chargeback is subjected to a fee of 20 Euro.
            </p>
          </el-tab-pane>
          <el-tab-pane label="CARDS" class="services-cards">
            <p class="services-text padding-top-115">
              A variety of benefits with UnionPay Debit Card:
            </p>
            <ul>
              <li class="services-list">Global Acceptability</li>
            </ul>
            <p class="services-text">
              In partnership with more than 2300 institutions worldwide,
              UnionPay International has enabled card acceptance in 179
              countries and regions with issuance in 61 countries and regions.
              UnionPay International provides high quality, cost effective and
              secure cross-border payment services to the world’s largest
              cardholder base and ensures convenient local services to a growing
              number of global UnionPay cardholders and merchants.
            </p>
            <ul>
              <li class="services-list">A Safe and Secure Way to Spend</li>
            </ul>
            <p class="services-text">
              UnionPay Debit Card is a secure substitute for carrying cash
              wherever you go and whenever you need it.
            </p>
            <ul>
              <li class="services-list">Expense Management</li>
            </ul>
            <p class="services-text">
              Your bank statement enables you to check the status of your
              account and manage your expenses easily. You can clearly see
              itemised details of all transactions conducted at POS locations
              and ATMs using UnionPay Debit Card.
            </p>
            <ul>
              <li class="services-list">Foreign Transactions</li>
            </ul>
            <p class="services-text">
              If you are travelling internationally, no more worries. Through
              your UnionPay Debit Card, all your foreign transactions will be
              converted from the transaction currency to US Dollars.
            </p>
            <ul>
              <li class="services-list">
                Cash Withdrawals from ATMs round-the-clock You can easily
                withdraw cash round the clock using your UnionPay Debit Card
                from any ATM.
              </li>
            </ul>
            <ul>
              <li class="services-list">24-Hours Customer Service</li>
            </ul>
            <p class="services-text">
              Our managers await to assist you 24/7. Whether to resolve a
              complaint, report a lost or stolen card or simply activate your
              UnionPay Debit Card, all you have to do is call to your account
              manager for instant support.
            </p>
            <p class="services-text">
              For more information regarding UnionPay Debit Card, please call
              your account manager.
            </p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.tabPosition = "top";
    }
  },
};
</script>

<style lang="scss" scoped>
.services-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 30px;
}
.services {
  background: #d9deff;
}
.services-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  text-align: left;
  margin: 0;
  padding-bottom: 63px;
  padding-left: 129px;
}
.services-list {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  padding-left: 129px;
  line-height: 29px;
  color: #000000;
  text-align: left;
}
.services-title-block {
  width: 100%;
  text-align: left;
  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 43px;
    color: #000000;
    margin: 0;
  }
}
.services-list {
  margin-left: 30px;
  position: relative;
  padding-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 103px;
    width: 17px;
    height: 17px;
    background: url("../../assets/services/check.svg");
  }
}
.services-crypto {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/bitcoin.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-transfer {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/data-transfer.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-cash {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/wallet.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-union {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/placeholder.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-international {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/contract.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-cheque {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/timing.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.services-cards {
  position: relative;
  &::after {
    content: "";
    background: url("../../assets/services/wallet.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 264px;
    height: 264px;
  }
}
.padding-top-115 {
  padding-top: 115px;
}
.padding-top-145 {
  padding-top: 145px;
}

@media screen and (max-width: 1200px) {
  .services-text,
  .services-list {
    font-size: 18px;
  }

  .services-title-block {
    h2 {
      margin: 0 0 20px 20px;
    }
  }
}
</style>
