var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account"},[_c('div',{staticClass:"account-wrapper"},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header"},[_c('div',{class:[
            'header-item',
            { 'header-item__active': _vm.$route.name === 'details' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/details')}}},[_vm._v(" Account Details ")]),_c('div',{class:[
            'header-item',
            { 'header-item__active': _vm.$route.name === 'transactions' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/transactions')}}},[_vm._v(" Transactions ")]),_c('div',{class:[
            'header-item',
            {
              'header-item__active': _vm.$route.name === 'internationalTransfer',
            } ],on:{"click":function($event){return _vm.$router.push('/personal-account/international-transfer')}}},[_vm._v(" International transfer ")]),_c('div',{class:[
            'header-item',
            { 'header-item__active': _vm.$route.name === 'intraTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/intra-transfer')}}},[_vm._v(" Intra transfer ")])])]),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }