<template>
  <div class="mainVideo-wrapper">
    <ApiGuide />
  </div>
</template>

<script>
import ApiGuide from "./apiGuide.vue";

export default {
  components: {
    ApiGuide,
  },
};
</script>

<style lang="scss" scoped>
.mainVideo-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
}
</style>
