<template>
  <div class="contact_form_wrapper">
    <div class="content">

      <div class="title">
        contact us
      </div>

      <div class="form_block">
        <div class="input_block">
          <div class="input_name">Name *</div>
          <input type="text"
                 class="my_input"
                 v-model="name"
          >
        </div>

        <div class="input_block">
          <Phoncode @totalPhone="setTotalPhone"/>
        </div>

        <div class="input_block">
          <div class="input_name">E-mail *</div>
          <input type="text"
                 class="my_input"
                 v-model="email"
          >
        </div>

        <div class="input_block">
          <div class="input_name">Text <span class="optional">(optional)</span></div>
          <textarea class="my_textarea" v-model="text"/>
        </div>

        <div class="btn_block">
          <button class="my_btn" @click="sendContactForm">Send</button>
        </div>

        <div class="error"> {{this.isError}} </div>
        <div v-if="isSuccess" class="success"> Your message was successfully sent </div>
      </div>

    </div>
  </div>
</template>

<script>
import Phoncode from "../phonecode/Example.vue"

export default {

  components: {
    Phoncode,
  },

  data() {
    return {
      name: null,
      phone: null,
      nameOfCountry: null,
      email: "",
      text: null,
      isSuccess: false,
      isError: "",
    }
  },

  methods: {
    async sendContactForm() {
      this.isError = ""
      this.isSuccess = false

      if (this.name.length && this.email.length && this.phone.length !== 0) {

        console.log(this.email)

        if (this.email.search("^[A-z0-9]{1,15}.?[A-z0-9]{1,15}@[a-z]{2,20}.[a-z]{2,4}$") === -1) {
          this.isError = "Please enter valid E-mail"
          return
        }

        await this.$store.dispatch('sendContactForm', {
          project: "cleveland",
          subject: "",
          data: {
            name: this.name,
            email: this.email,
            message: this.text,
            phone: this.phone,
            nameOfCountry: this.nameOfCountry
          }
        }).then(() => {
          this.isSuccess = true
          this.isError = ""
        }).catch(() => {
          this.isError = "Oops, something went wrong"
        })
      }
    },

    setTotalPhone (data) {
      this.phone = data.totalPhoneNumber
      this.nameOfCountry = data.countryName
    }
  }

};
</script>

<style lang="scss" scoped>

.contact_form_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 115px;
  margin-bottom: 96px;

  .content {
    width: 476px;
    height: 715px;

    .title {
      text-transform: uppercase;
      font-family: 'Montserrat',sans-serif;
      font-weight: 500;
      font-size: 35px;
      line-height: 43px;
      color: #000000;
      margin-bottom: 64px;
    }

    .form_block {
      width: 100%;

      .input_block {
        margin-bottom: 30px;

        .input_name {
          font-family: 'Roboto',serif;
          font-style: normal;
          font-weight: 300;
          font-size: 23px;
          line-height: 27px;
          color: #000000;
          margin-bottom: 15px;
          text-align: start;

          .optional {
            color: #B3B3B3;
          }
        }

        .my_input {
          background: #FFFFFF;
          border: 1px solid #4353AC;
          border-radius: 5px;
          width: 100%;
          height: 45px;
          outline: none;
          box-sizing: border-box;
        }

        .my_textarea {
          background: #FFFFFF;
          border: 1px solid #4353AC;
          border-radius: 5px;
          width: 100%;
          height: 130px;
          outline: none;
          box-sizing: border-box;
        }
      }

      .btn_block {

        .my_btn {
          font-family: 'Montserrat',serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;
          width: 184px;
          height: 45px;
          background: linear-gradient(90deg, #FDC006 0%, #FDC006 0.01%, #F99300 94.81%);
          border-radius: 30px;
          border: none;
          cursor: pointer;
        }
      }

      .error {
        font-family: 'Montserrat',serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #EB5757;
        margin-top: 20px;
      }

      .success {
        color: #27AE50;
        margin-top: 20px;
        font-family: 'Montserrat',serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

</style>
