<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <el-backtop :bottom="128">
      <div class="back-top-button">
        <i class="el-icon-arrow-up" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from "./components/home/header";
import Footer from "./components/home/footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
@font-face {
  font-family: "Montserrat-Black";
  src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("./assets/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-LightItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto-black";
  src: url("./assets/fonts/Roboto/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("./assets/fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("./assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url("./assets/fonts/Roboto/RobotoCondensed-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoCondensed-BoldItalic";
  src: url("./assets/fonts/Roboto/RobotoCondensed-BoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "RobotoCondensed-Italic";
  src: url("./assets/fonts/Roboto/RobotoCondensed-Italic.ttf")
    format("truetype");
}
@font-face {
  font-family: "RobotoCondensed-Light";
  src: url("./assets/fonts/Roboto/RobotoCondensed-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoCondensed-LightItalic";
  src: url("./assets/fonts/Roboto/RobotoCondensed-LightItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/RobotoCondensed-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("./assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("./assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("./assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("./assets/fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
}
#app {
  font-family: Roboto, Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.d-flex-rn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.d-flex-rw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.d-flex-cn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.d-flex-cw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}
.flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-start-center {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-end-center {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-end-start {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-space-between {
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-space-between-str {
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-space-around {
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
ul {
  padding-left: 0;
  margin: 0;
}
li {
  list-style-type: none;
}

.el-input__inner {
  border: none !important;
}
.el-select .el-input .el-select__caret {
  color: #000 !important;
}
.el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
}
.el-tabs--left .el-tabs__header.is-left {
  width: 40% !important;
}
.el-tabs__item {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 25px !important;
  line-height: 29px !important;
  color: rgba(0, 0, 0, 0.7) !important;
  margin-bottom: 25px;
  padding-left: 0 !important;
}
.el-tabs__item:hover {
  color: #000 !important;
}
.el-tabs__item.is-active {
  color: #000 !important;
}
.el-tabs--left .el-tabs__nav.is-left,
.el-tabs--left .el-tabs__nav.is-right,
.el-tabs--right .el-tabs__nav.is-left,
.el-tabs--right .el-tabs__nav.is-right {
  padding-top: 115px !important;
}
.el-tabs--left .el-tabs__active-bar.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-left::after {
  display: none !important;
}
.el-checkbox__inner {
    border-color: #9c9fa7;
}
.form-wrapper {
  .el-input {
    border: solid 1px #c4c4c5!important;
  }
}
.error-message-wrapper {
    position: relative;
    top: 26px;
}
</style>
