<template>
  <div class="ourCorporate">
    <div class="ourCorporate-wrapper">
      <div class="ourCorporate-content">
        <h2 class="title">CORPORATE</h2>
        <p class="text">INDUSTRIES</p>
        <p class="text">
          Cleveland Bank specializes in arrangement of different kind of
          businesses even high-risk ones as well offshore jurisdictions. With
          our network of offshore and domestic banking partners, we are able to
          provide a solution for your business even if you are from a high risk
          business, or you are facing difficulties to open a bank account. You
          can be sure wherever we provide support, we will take the time to
          understand your goals and needs to ensure your business capital works
          as hard as possible. Long-term partnership relationships with our
          customers and partners are the best evidence for successful
          cooperation.
        </p>
        <p class="text">PRIVACY POLICY</p>
        <p class="text">
          Cleveland Bank specializes in arrangement of different kind of
          businesses even high-risk ones as well offshore jurisdictions. With
          our network of offshore and domestic banking partners, we are able to
          provide a solution for your business even if you are from a high risk
          business, or you are facing difficulties to open a bank account. You
          can be sure wherever we provide support, we will take the time to
          understand your goals and needs to ensure your business capital works
          as hard as possible. Long-term partnership relationships with our
          customers and partners are the best evidence for successful
          cooperation.
        </p>
        <p class="text">PRIVACY POLICY</p>
        <p class="text">
          We recognize, respect and protect the personal privacy rights of all
          Cleveland Bank clients. Cleveland Bank realizes that you entrust us
          with personal information, and it is our policy to maintain that
          information in a confidential manner. We are committed to providing
          the highest level of security and privacy regarding the collection and
          use of your personal information.
        </p>
        <p class="text">
          The bank collects nonpublic personal information about you from the
          following sources:
        </p>
        <ul>
          <li class="text list">
            Information we receive from you on applications or other forms;
          </li>
          <li class="text list">
            Information about your transactions with us.
          </li>
        </ul>
        <p class="text">COOKIES POLICY</p>
        <p class="text">
          Cookies are text files containing small amounts of information which
          are downloaded to visitor’s computer or device when he/she visits
          Bank’s website or internet-enabled service. Cookies are then sent back
          to the originating web domain on visitor’s subsequent visits to that
          domain.
        </p>
        <p class="text">
          Cookies are useful because they allow a website or internet-enabled
          service to recognize a user’s device. Cookies allow the visitor to
          navigate between pages efficiently, remember preferences and generally
          improve the user experience and for the Bank to make the banking
          services more secure. Session cookies are deleted automatically when
          the user closes the browser and persistent cookies remain on the
          device after the browser is closed (for example to remember user’s
          preferences when he/she returns to the site or service). The Bank also
          uses cookies as help to compile aggregate data about site traffic and
          site interaction so that the Bank can offer better site experiences
          and tools in the future.
        </p>
        <p class="text" id="section-seven">
          By visiting Bank’s webpage or by using the Internet Banking the
          visitors/clients consent to the use of the cookies set out in this
          Policy. If the client does not consent to the use of the cookies
          he/she should not browse the Bank’s webpage, should not access Bank’s
          internet banking.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ourCorporate {
  background: #d9deff;
  position: relative;
  padding: 80px 20px;
}
.ourCorporate-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  text-align: left;
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  text-align: left;
}
.list {
  position: relative;
  margin-left: 30px;
  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -26px;
    background: url("../../assets/corporate/check.svg");
    width: 17px;
    height: 17px;
  }
}
@media screen and (max-width: 1200px) {
  .text {
    font-size: 18px;
  }
}
</style>
