<template>
  <div>
    <CustomDialog
        :visible="showRefundDialog"
        title="Refund transfer"
        cancelBtn="Cancel"
        :confirmBtn="confirmBtnText"
        @confirm="handleConfirmRefund"
        @close="closeDialog"
        :loadingConfirmButton="loadingConfirmButton"
        width="400px"
    >
      <template v-slot:body>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <div class="login-wrapper">
          <p>Refund transaction</p>
          <p>
            <b>{{ selectedRow.transfer_number }}</b>
          </p>
          <el-select
              placeholder="Select reason"
              v-model="selectedRefundReason"
              class="input"
          >
            <el-option
                v-for="item in refundReasons"
                :key="item"
                :value="item"
                :label="item"
            ></el-option
            >
          </el-select>
        </div>
      </template>
    </CustomDialog>
    <div class="transaction-wrapper">
      <div class="content">
        <div class="filter-wrapper">
          <el-select
              class="filter-wrapper__select"
              v-model="selectedAccount"
              filterable
              placeholder="Filter by account"
              value-key="currency"
          >
            <el-option
                v-for="item in accountTransactions"
                :key="item.label"
                :label="item.label"
                :value="item"
            >
            </el-option>
          </el-select>
          <div @click="printTable">
            <PrintIcon/>
          </div>
        </div>
        <div
            :data="currentTransactions"
            style="width: 100%; display: flex;"
        >
          <div>
            <p style="color: #909399; margin: 10px 5px ">Date</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.date.slice(0, 10) }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Transfer Type</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.transfer_type_name }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Sender</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.account_special_number }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Beneficiary</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
          <span class="table-cell">{{ t.beneficiary_address }}<p
              v-if="t.shopTransactionNumber">{{ 'Shop: ' }}{{ t.shopTransactionNumber }}</p></span>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Transaction Number</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.transfer_number }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Debit</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.debit }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">credit</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.credit }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Status</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.status_name }}</p>
            </div>
          </div>
          <div>
            <p style="color: #909399; margin: 10px 5px">Balance</p>
            <div class="table-cell_color" v-for="t in currentTransactions" :key="t.date">
              <p class="table-cell">{{ t.balance }} {{ t.currency_abbreviation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'
import CustomDialog from "../customDialog";

export default {
  data() {
    return {
      selectedAccount: null,
      showRefundDialog: false,
      selectedRefundReason: null,
      refundReasons: [
        "Duplicate",
        "Fraudelent",
        "Requested by customer",
        "Other",
      ],
      selectedRow: null,
      errorMessage: null,
      interval: null,
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction");
    this.interval = setInterval(
        () => this.$store.dispatch("getAccountTransaction"),
        5000
    );
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/");
      }
    }
  },

  unmounted() {
    clearInterval(this.interval);
  },

  methods: {
    handleRefund(index, row) {
      console.log(index, row);
      this.showRefundDialog = true;
      this.selectedRow = row;
    },

    async handleConfirmRefund() {
      this.errorMessage = null;
      if (!this.selectedRefundReason) {
        this.errorMessage = "You must select refund reason";
        return;
      }

      await this.$store
          .dispatch("sendRefundTransfer", {
            transfer_number: this.selectedRow.transfer_number,
            refund_reason: this.selectedRefundReason,
          })
          .then((response) => {
            console.log(response);
            if (response.code) throw response;
            this.showRefundDialog = false;
            this.selectedRefundReason = null;
          })
          .catch((err) => {
            this.errorMessage = err.message;
          });
    },

    closeDialog() {
      this.showRefundDialog = false;
      this.selectedRefundReason = null;
      this.errorMessage = null;
    },
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      const incomingTransferTypes = ["incoming transfer", "shop transaction"];
      if (this.selectedAccount)
        return this.selectedAccount.transaction.map((el) => {
          if (
              incomingTransferTypes.includes(el.transfer_type_name.toLowerCase())
          ) {
            return {
              ...el,
              balance: el.balance === null ? "-" : el.balance,
              account_special_number: el.beneficiary_name,
              beneficiary_name: el.account_special_number,
            };
          } else {
            return {...el, balance: el.balance === null ? "-" : el.balance};
          }
        });
      return this.$store.getters.allTransactions.map((el) => {
        if (
            incomingTransferTypes.includes(el.transfer_type_name.toLowerCase())
        ) {
          return {
            ...el,
            balance: el.balance === null ? "-" : el.balance,
            account_special_number: el.beneficiary_name,
            beneficiary_name: el.account_special_number,
          };
        } else {
          return {...el, balance: el.balance === null ? "-" : el.balance};
        }
      });
    },
  },

  components: {
    CustomDialog,
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
  width: 100%;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #fcb004;
  border-radius: 60px;
  z-index: 1111;
  padding: 6px;

  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  border-radius: 60px;
}

.error-message {
  color: #d00000;
}

.table-cell {
  margin: 0;
  padding: 0 20px;
  min-width: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-cell_color:nth-child(even) {
  background-color: #edefef;
}

/deep/ td:last-child .cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
