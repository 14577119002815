<template>
  <div class="intra-transfer-wrapper">
    <h2>International Transfer</h2>
    <p>Convert currency - enter transfer information</p>

    <div class="fields">
      <el-select
        class="fields__select"
        v-model="firstSelected"
        filterable
        placeholder="From account number"
      >
        <el-option
          v-for="item in accountDetails"
          :key="item.id"
          :label="item.account_special_number"
          :value="item.account_special_number"
        >
        </el-option>
      </el-select>

      <el-select
        class="fields__select"
        v-model="secondSelected"
        filterable
        placeholder="To account number"
        :disabled="!firstSelected"
      >
        <el-option
          v-for="item in accountDetails"
          :key="item.id"
          :disabled="item.account_special_number === firstSelected"
          :label="item.account_special_number"
          :value="item.account_special_number"
        >
        </el-option>
      </el-select>
    </div>

    <div class="fields">
      <el-input
        class="fields__input"
        placeholder="Amount"
        v-model="amount"
        type="number"
      />
      <el-input
        class="fields__input"
        placeholder="Reference"
        v-model="reference"
      />
    </div>
    <p v-if="showErrorMessage" class="errorMessage">
      All fields must be filled
    </p>
    <el-checkbox v-model="checked"
      >I agree with Cleveland Terms and Conditions</el-checkbox
    >

    <el-button
      type="primary"
      :disabled="!checked"
      @click="sendTransfer"
      :loading="loadingSubmitButton"
    >
      Send
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstSelected: null,
      secondSelected: null,
      amount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/");
      }
    }
  },

  methods: {
    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected && !secondSelected && !amount && !reference) {
        this.showErrorMessage = true;
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store.dispatch("sendIntraTransfer", {
        amount,
        from_account: firstSelected,
        to_account: secondSelected,
        reference,
      });
      this.firstSelected = null;
      this.secondSelected = null;
      this.amount = null;
      this.reference = null;
      this.checked = false;
      this.loadingSubmitButton = false;
    },
  },

  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
}

.el-checkbox {
  margin-bottom: 15px;
}

.fields {
  display: flex;
  margin-bottom: 25px;

  &__select {
    margin-right: 25px;
    width: 300px;
  }

  &__input {
    width: 300px;
    margin-right: 25px;
  }
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #fff;
  background-color: #f56c6c;
  border-radius: 30px;
}
.el-button--primary {
  color: #fff;
  background: linear-gradient(90deg, #4356be -12.94%, #414769 100%), #414769;
}
</style>
