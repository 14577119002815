<template>
  <div class="table-wrapper">
    <h2>Account Details</h2>
    <el-table :data="accountDetails" style="width: 100%">
      <el-table-column
        prop="account_special_number"
        label="Account Number"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="account_type_name"
        label="Account Type"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="currency_abbreviation"
        label="Currency"
        width="150"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="status"
        label="Status"
        :formatter="(row, column, cellValue) => cellValue ? 'Active' : 'Deactive'"
      >
      </el-table-column> -->
      <el-table-column prop="balance" label="Balance" width="90" align="center">
      </el-table-column>
      <el-table-column
        prop="min_balance"
        label="Minimum Balance"
        width="200"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 50px;
  border-radius: 60px;
  margin: 70px 0;
  width: 100%;

  h2 {
    font-family: Montserrat-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    text-transform: uppercase;
    color: #000000;
  }
}
</style>
