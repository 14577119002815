<template>
  <div class="feesAim">
    <div class="feesAim-wrapper">
      <div class="feesAim-content">
        <div class="fees-block">
          <p class="title">FEES</p>
        </div>
        <div class="text-block">
          <p class="text" id="section-nine">
            We aim to keep our rates and charges clear and easy to understand,
            so its less complicated for you to draw comparisons as well as make
            well-informed decisions when it comes to your money. To know more,
            get in touch!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.feesAim {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.feesAim-content {
  flex-wrap: wrap;
  display: flex;
}
.feesAim-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.fees-block {
  width: 120px;
  margin: 10px 20px;
}
.text-block {
  width: 90%;
  max-width: 800px;
  margin: 10px 20px;
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  margin: 0;
  text-align: left;
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  margin: 0;
  text-align: left;
}
@media screen and (max-width: 1200px) {
  .text {
    font-size: 18px;
  }
}
</style>
