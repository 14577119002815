<template>
  <el-dialog :title="title" :visible.sync="dialogState" :width="width">
    <slot name="body"></slot>
    <div slot="footer" class="dialog-footer">
      <el-button
        @click="dialogState = true"
        type="primary"
        :loading="loadingConfirmButton"
      >
        {{ confirmBtn }}
      </el-button>
      <el-button @click="dialogState = false" type="danger">{{
        cancelBtn
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      type: String,
      default: "Cancel",
    },
    confirmBtn: {
      type: String,
      default: "Confirm",
    },
    loadingConfirmButton: {
      type: Boolean,
      default: false,
    },
    width: String,
  },

  computed: {
    dialogState: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit(value ? "confirm" : "close", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  background: linear-gradient(90deg, #4356be -12.94%, #414769 100%), #414769;
  border: none;
}

.el-button--danger {
  background: #c4c4c4;
  border: none;
}
</style>
