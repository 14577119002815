<template>
  <CustomDialog
    :visible="showSignUpDialog"
    :title="this.lang.text123"
    cancelBtn="Cancel"
    confirmBtn="Send"
    :loadingConfirmButton="loadingSignUp"
    @confirm="signUp"
    @close="$emit('close')"
    width="650px"
  >
    <template v-slot:body>
      <div class="dialog-body">
        <div class="form-wrapper">
          <div class="input-wrapper">
            <span>First Name</span>
            <el-input
              placeholder="Enter your first name"
              v-model="firstName"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !firstName"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>

          <div class="input-wrapper">
            <span>Last Name</span>
            <el-input
              placeholder="Enter your last name"
              v-model="lastName"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !lastName"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-wrapper">
          <div class="input-wrapper">
            <span>Country</span>
            <el-input
              placeholder="Enter your country"
              v-model="country"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !country"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>

          <div class="input-wrapper">
            <span>City</span>
            <el-input
              placeholder="Enter your city"
              v-model="city"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !city"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-wrapper">
          <div class="input-wrapper">
            <span>Address</span>
            <el-input
              placeholder="Enter your address"
              v-model="address"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !address"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>

          <div class="input-wrapper">
            <span>Post code</span>
            <el-input
              placeholder="Enter your post code"
              v-model="postCode"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !postCode"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-wrapper">
          <div class="input-wrapper">
            <span>Phone Number</span>
            <el-input
              placeholder="Enter your phone number"
              v-model="phone"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !phone"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>

          <div class="input-wrapper">
            <span>E-mail</span>
            <el-input
              placeholder="Enter your email"
              v-model="email"
              class="input"
              type="email"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !email"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>

        <div class="form-wrapper">
          <div class="input-wrapper">
            <span>Password</span>
            <el-input
              placeholder="Enter your password"
              type="password"
              v-model="password"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !password"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>

          <div class="input-wrapper">
            <span>Confirm password</span>
            <el-input
              placeholder="Confirm your password"
              type="password"
              v-model="confirmPassword"
              class="input"
            />
            <div class="error-message-wrapper">
              <span
                v-if="errorMessage && !confirmPassword"
                class="error-message"
              >
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>

      </div>
    </template>
  </CustomDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomDialog from './customDialog'

export default {
  computed: {
    ...mapGetters(['lang']),
  },
  props: {
    showSignUpDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      country: null,
      city: null,
      address: null,
      postCode: null,
      password: null,
      phone: null,
      email: null,
      confirmPassword: null,
      loadingSignUp: false,
      errorMessage: null,
      fields: [
        'firstName',
        'lastName',
        'country',
        'city',
        'address',
        'postCode',
        'password',
        'phone',
        'email',
        'confirmPassword',
      ],
    }
  },

  methods: {
    async signUp() {
      let isValid = true
      this.fields.forEach((item) => {
        if (!this[item]) isValid = false
      })

      if (!isValid) {
        this.errorMessage = 'This field must be filled'
        return
      }

      this.loadingSignUp = true
      await this.$store.dispatch('signUp', {
        'First Name': this.firstName,
        'Last Name': this.lastName,
        Country: this.country,
        City: this.city,
        Address: this.address,
        'Post Code': this.postCode,
        'Telephone Number': this.phone,
        'E-mail': this.email,
        Password: this.Password,
        'Confirm password': this.confirmPassword,
      })

      this.loadingSignUp = false
      this.firstName = null
      this.lastName = null
      this.country = null
      this.city = null
      this.address = null
      this.postCode = null
      this.phone = null
      this.email = null
      this.Password = null
      this.confirmPassword = null
      this.errorMessage = null
      this.$emit('close')

      this.$message({
        message: 'Your request has been sent!',
        type: 'success',
      })
    },
  },

  components: {
    CustomDialog,
  },
}
</script>

<style lang="scss" scoped>
  .dialog-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-wrapper {
    display: flex;
    width: auto;

    .input {
      width: 250px;
      margin-right: 25px;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 5px;
      margin-left: 2px;
    }
  }

  .error-message-wrapper {
    margin-top: -25px;
    height: 45px;
  }

  .error-message {
    color: #cc3f55;
  }
</style>
