<template>
  <div class="what-we-do-wrapper">
    <div class="what-we-do-content d-flex-rw">
      <div class="what-we-do-open-block">
        <span class="title">ABOUT CLEVELAND BANK</span>
        <!-- v-if="isLearnMode" -->
        <p class="text">
          <span>
            Cleveland International Bank now enjoys all the benefits of a legal
            bank and can entertain its clientele without any hindrances under
            the new jurisdiction. Banking License Number: 159 20/ CIBL Central
            Reserve Authority of SADR.
          </span>
          <span>
            Our experienced and talented teams of corporate banking
            professionals provide a wide range of financing solutions to meet
            the needs of any company. We also offer a comprehensive suite of
            management solutions to help you manage your cash flow more
            effectively.</span
          >
        </p>
        <!-- <div v-if="!isLearnMode" class="button-container">
            <button class="btn-more" @click="isLearnMode = true" round>
              Learn more
            </button>
          </div> -->
      </div>
      <div class="what-we-do-info-block">
        <div class="text">
          <div class="what-we-do-paragraph">
            Cleveland International Bank has been serving its clients for many
            years now, and has had a wonderful track record for its services
            rendered till date. It has strived to follow all the rules and
            regulations set forth by the governing authorities of the country
            which has given its clients a safe banking experience along with all
            the features and benefits as an offshore bank.
          </div>
          <!-- v-if="isLearnMode" -->
          <div>
            After a careful search and researching the best alternatives for
            carrying out all offshore banking operations, it was decided to
            register our legal entity under the license obtained from the
            jurisdiction of the Western Sahara.
          </div>
        </div>
      </div>

      <div class="icon-section">
        <div class="text-block text e-banking-icon">
          <span class="icon-title">E-banking</span>
          <span
            >You do not need to visit banks for transferring money which can be
            done from anywhere just with your laptop.
          </span>
        </div>
        <div class="text-block text persona-icon mar-left-170">
          <span class="icon-title">PERSONALIZED ASSISTANCE</span>
          <span
            >Your dedicated Account manager will personally answer your calls.
            Opening a bank account online is fast and easy. It can take few days
            and you will not need to visit a bank branch.</span
          >
        </div>

        <div class="text-block account-icon text">
          <span class="icon-title">Quick Account Opening</span>
          <span>
            High-qualified workers of our bank are always here to provide you
            with the best service. The accounts of Cleveland Bank are most
            secured. Keep money safe and leave the client satisfied.
          </span>
        </div>
        <div class="text-block text valeus-icon mar-left-170">
          <span class="icon-title">Our Values</span>
          <span
            >Our purpose is to be the most trusted bank and safeguard client
            accounts.
          </span>
          <ul>
            <li>Honesty</li>
            <li>Integrity</li>
            <li>Innovation</li>
            <li>Professionalism</li>
            <li>Transparency</li>
            <li>Customer focus</li>
            <li id="section-two">Respect</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.what-we-do-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 100px;

  .title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 35px;
    line-height: 150%;
    color: #000000;
    display: block;
    text-align: left;
    width: 78%;
  }
  .icon-title {
    padding-bottom: 30px;
  }
  ul {
    padding-top: 15px;
    li {
      padding-bottom: 10px;
      margin-left: 30px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -30px;
        width: 17px;
        height: 17px;
        background: url("../../assets/about/check.svg");
      }
    }
  }
  .text {
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
  }
  .what-we-do-open-block {
    width: 35%;
    margin-right: 93px;
  }
  .what-we-do-info-block {
    width: 55%;
  }
  .what-we-do-paragraph {
    margin-bottom: 50px;
  }
  .mar-left-170 {
    margin-left: 170px;
  }
  .btn-more {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    border: none;
    background: none;
    cursor: pointer;
    padding-right: 70px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 8px;
      right: 20px;
      width: 30px;
      height: 4px;
      background: url("../../assets/about/arrow.svg");
    }
  }
  .text-block {
    width: 450px;
    margin-bottom: 84px;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 10px;
    width: 90%;
    padding: 30px 10px;

    .d-flex-rw {
      justify-content: center;
    }

    .title {
      margin-bottom: 10px;
    }
    .what-we-do-open-block,
    .what-we-do-info-block {
      width: 95%;
      margin-right: 0px;
    }
    .d-flex-rn {
      flex-direction: column;
      align-items: center;
    }
    .icon-section {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .text-block {
      align-self: center;
      width: 100%;
      flex-wrap: none;
      flex-direction: column;
      // padding: 20px;
    }
  }
}
.icon-section {
  margin-top: 84px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.e-banking-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/about/dollar.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.persona-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/about/smartphone.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.account-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/about/hourglass.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.valeus-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/about/hand.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.button-container {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: 50px;
}

@media screen and (max-width: 1200px) {
  .what-we-do-wrapper {
    font-size: 16px;
  }

  .mar-left-170 {
    margin-left: 0 !important;
  }

  .text {
    font-size: 18px !important;
  }

  .e-banking-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .persona-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .account-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .valeus-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
}
</style>
