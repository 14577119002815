<template>
  <div class="home-wrapper">
    <!-- <div class="rate-wrapper">
      <h2 class="title">Euro exchange rate</h2>
      <div class="rates">
        <div class="rate" v-for="rate in rates" :key="rate.currency">
          <span class="rate__name">{{ rate.currency }}: </span>
          <span class="rate__value">{{ rate.currency_value }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      rates: [
        { currency: 'BTC', currency_value: 0.0001317 },
        { currency: 'ETH', currency_value: 0.006813 },
        { currency: 'BCH', currency_value: 0.003301 },
        { currency: 'USDT', currency_value: 1.11 },
        { currency: 'LTC', currency_value: 0.0198 },
        { currency: 'CNY', currency_value: 7.653 },
        { currency: 'CHF', currency_value: 1.0757 },
        { currency: 'GBP', currency_value: 0.84445 },
        { currency: 'USD', currency_value: 1.1088 },
        { currency: 'TRY', currency_value: 6.5673 },
      ],
    }
  },
  computed: {
    ...mapGetters(['lang']),
  },
}
</script>

<style lang="scss" scoped>
  .home-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rate-wrapper {
    width: 842px;
    text-align: left;
    padding-bottom: 30px;
  }

  .rates {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
  }

  .rate {
    width: 150px;
    margin-right: 15px;
    margin-bottom: 25px;
    font-size: 18px;

    &__name {
      color: #cc3f55;
    }
  }
</style>
