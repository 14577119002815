<template>
  <div class="apiGuide">
    <div class="apiGuide-wrapper">
      <div class="apiGuide-content">

        <p class="title">API Guide</p>

        <div class="block">
          <div class="endpoint-block">
            <h2><b>Parameters are passed in JSON format</b></h2>
            <div>
              <h3 class="subtitle">
                headers block is required for all types of requests
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                headers : { <br/>
                Content-Type: application/json, <br/>
                Authorization: Bearer TOKEN, (Get it after successful authorization)<br/>
                }
              </p>
            </div>
          </div>
          <div class="endpoint-block">
            <h3><b>Available endpoints:</b></h3>
            <div>
              <h3 class="subtitle">
                Log In
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: POST <br>
                EndPoint: https://api1.adataprotect.com/api/user/login <br>
                <br>
                Params: <br>
                <br>
                login - string - your login <br>
                password - string - your password <br>
              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “token” : TOKEN,<br>
                </div>
                }<br><br>

                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Tool Signature Request:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: POST <br>
                EndPoint: /api/transaction/creates/payments <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - your id in our system (check with the admin) <br>
                owner - string - Payer's name (From the card) <br>
                card_number - string - Card number (From the card) <br>
                cvv - integer - number on the back of the card (From the card) <br>
                validity - string - card expiry date. Example: “01/22” (From card) <br>
                amount - float/integer - write-off amount <br>
                currency - string - currency . Example: "USD", always in upper case. <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {<br>
                  “transaction” : “transaction number” ,(99681628714189)<br>
                  “status”: 1|2|3|5,<br>
                  “redirect_url”: “http://exemple.com/…..” (3DS, option)<br>

                </div>


                <div class="tab">}</div>
                }<br><br>

                *redirect_url - url to redirect the user to pass 3DS.<br>
                after passing the 3DS, the user will be redirected to the authentication completion page.<br>

                Possible statuses:<br>
                STATUS_IN_PROCESS = 1;<br>
                STATUS_APPROVED = 2;<br>
                STATUS_DENIED = 3;<br>
                STATUS_WAITING_CONFIRMATION = 5;<br>

                “status”: “STATUS_IN_PROCESS” - Transaction in progress, check status after a while<br>
                “status”: “STATUS_APPROVED” - Transaction completed<br>
                “status”: “STATUS_DENIED” - The transaction was NOT completed due to reasons beyond our control <br>
                “status”: “STATUS_WAITING_CONFIRMATION” - waiting for confirmation from the user<br><br>


                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transaction status request:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: GET<br>
                EndPoint: api/transaction/transactions/{id}/status <br>
                <br>
                Params: <br>
                <br>
                id - string - transaction number (you receive in the response with a successful request to withdraw funds)<br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {<br>
                  “status” : (int) transaction status<br>

                </div>


                <div class="tab">}</div>
                }<br><br>

                Possible statuses:<br>
                STATUS_IN_PROCESS = 1;<br>
                STATUS_APPROVED = 2;<br>
                STATUS_DENIED = 3;<br>
                STATUS_REFUND = 4; <br>
                STATUS_WAITING_CONFIRMATION = 5;<br><br>


                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Refund:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: POST<br>
                EndPoint: /api/transaction/refunds <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - ваш id в нашей системе (уточнять у админа)<br>
                transaction_id - string - номер транзакции<br>
                <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : "OK"<br>
                  }<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : “Query params is not valid”<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 403,<br>
                  “errors” : “Query params is not valid”<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Sever Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transaction info:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: GET<br>
                EndPoint: https://api1.adataprotect.com/api/transaction/v1/transactions/{transaction number}/info <br>
                <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {
                      <div class="tab">
                        "transaction_number": "94621337271640",<br>
                        "transaction_status": 3,<br>
                        "transaction_amount": 0.01,<br>
                        "net_amount": 0.01,<br>
                        "transaction_date_time": "2021-11-22 08:40:40 UTC",<br>
                        "is_live_transaction": true,<br>
                        "card_owner_name": "Test Test",<br>
                        "card_number": "6690"<br>
                      </div>
                  }<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : “Transaction is not found”<br>
                </div>
                }<br><br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transactions list:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                <br/>
                Method: POST<br>
                EndPoint: https://api1.adataprotect.com/api/transaction/all/transactions/info<br>
                <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - your id in our system (check with the admin) <br>
                isLiveTransaction - string - optional (flag test transaction or not) <br>
                startDate - string - optional (passing date in timestamp format) <br>
                endDate - string - optional (passing date in timestamp format) <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : <br>
                  [ <br>
                  <div class="tab">
                    { <br>
                    "transaction_number": "94621337271640",<br>
                    "transaction_status": 3,<br>
                    "transaction_amount": 0.01,<br>
                    "net_amount": 0.01,<br>
                    "transaction_date_time": "2021-11-22 08:40:40 UTC",<br>
                    "is_live_transaction": true,<br>
                    "card_owner_name": "Test Test",<br>
                    "card_number": "6690"<br>
                    }
                  </div>
                  <div class="tab">{...}</div>
                  <div class="tab">{...}</div>
                  <div class="tab">{...}</div>
                  ]<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab" id="section-eight">
                  “status” : 418,<br>
                  “errors” : “Missing params”<br>
                </div>
                }<br><br>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

.tab {
  margin-left: 15px;
}

.example-title {
  display: flex;
  margin-top: 15px;
}

.example {
  max-width: 940px;
  min-width: 70%;
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: rgb(32, 38, 118);
  background: rgb(255, 255, 255);
  //box-shadow: rgb(0 0 0 / 10%) 0 0 11px;
  border-radius: 5px;
  padding: 16px 20px;
  margin: 20px 0;
}

.subtitle {
  display: flex;
  margin-top: 15px;
}

p {
  margin: 0;
}

.btn-orange {
  text-align: left;
  margin: 30px 0;

  .el-button {
    background: linear-gradient(
            90deg,
            #fdc006 0%,
            #fdc006 0.01%,
            #f99300 94.81%
    );
    color: #fff;
    width: 135px;
    height: 45px;
    border: none;
    transition-duration: 1s;

    &:hover {
      background: #f78989;
      transition-duration: 1s;
    }
  }
}

.apiGuide {
  position: relative;
  padding-bottom: 100px;
}

.apiGuide-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.endpoint-block {
  background: #d9deff;
  padding: 34px;
  width: 100%;
  margin-right: 28px;
  @media screen and (max-width: 550px) {
    width: 80%;
    padding: 10px;
  }
}

.endpoint-block {
  background: #d9deff;
  padding: 34px;
  width: 100%;
  margin-right: 28px;
  @media screen and (max-width: 550px) {
    width: 80%;
    padding: 10px;
  }
}

.block {
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
  //div:first-child {
  //  background: #d9deff;
  //  padding: 34px;
  //  width: 100%;
  //  margin-right: 28px;
  //  @media screen and (max-width: 550px) {
  //    width: 80%;
  //    padding: 10px;
  //  }
  //}
  //
  //div:last-child {
  //  width: 100%;
  //  background-size: cover;
  //  background-repeat: no-repeat;
  //  @media screen and (max-width: 1100px) {
  //    background: none;
  //  }
  //  @media screen and (max-width: 550px) {
  //    width: 80%;
  //  }
  //}

  img {
    display: none;
    @media screen and (max-width: 1100px) {
      display: block;
      margin-top: 20px;
      width: 100%;
    }
  }
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  margin: 0;
  text-align: left;
  margin-bottom: 70px;
  @media screen and (max-width: 550px) {
    font-size: 26px;
  }
}

.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  text-align: left;
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
}

.text-big {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
  @media screen and (max-width: 550px) {
    font-size: 18px;
  }
}

.text-blue {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  text-transform: uppercase;
  color: #4354b0;
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .text {
    font-size: 16px;
  }
}
</style>
