<template>
  <div class="openAccount-wrapper">
    <div class="openAccount-content d-flex-rw flex-center">
      <div class="openAccount-header-block">
        <div class="openAccount-title-block">
          <h2 class="openAccount-title">
            <span>HOW</span>
            TO OPEN AN ACCOUNT
          </h2>
        </div>
        <div class="openAccount-subtitle-block">
          <p class="openAccount-subtitle">
            Cleveland bank was created to work with accounts of both corporate
            and private clients.
          </p>
        </div>
      </div>
      <div class="openAccount-main-container">
        <img src="../../assets/openAccount/open-account-guide.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.openAccount-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 150px;
  .openAccount-header-block {
    margin-bottom: 143px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  .openAccount-title-block {
    width: 100%;
    text-align: left;
    max-width: 400px;
    span {
      display: block;
    }
    .openAccount-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 43px;
      color: #000000;
      margin: 0;
    }
  }
  .openAccount-subtitle-block {
    text-align: left;
    max-width: 400px;
  }
  .openAccount-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
    margin: 0;
  }
  .openAccount-main-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .openAccount-main-container img {
    width: 95%;
    max-width: 670px;
  }
  .openAccount-step-block {
    .openAccount-step-block-one {
      width: 317px;
      text-align: center;
      margin-bottom: 120px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -61px;
        right: 240px;
        width: 242px;
        height: 189px;
        background: url("../../assets/openAccount/union.svg");
        background-repeat: no-repeat;
      }
      &::after {
        content: "";
        position: absolute;
        top: -65px;
        right: -136px;
        width: 125px;
        height: 166px;
        background: url("../../assets/openAccount/money-bag.png");
        background-repeat: no-repeat;
      }
      span::before {
        content: "";
        position: absolute;
        top: -60px;
        right: 136px;
        width: 40px;
        height: 48px;
        background: url("../../assets/openAccount/1.png");
        background-repeat: no-repeat;
      }
    }
    .openAccount-step-block-two {
      width: 317px;
      text-align: center;
      margin-bottom: 120px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -44px;
        right: 240px;
        width: 242px;
        height: 189px;
        background: url("../../assets/openAccount/contract.png");
        background-repeat: no-repeat;
      }
      &::after {
        content: "";
        position: absolute;
        top: -65px;
        right: -136px;
        width: 240px;
        height: 242px;
        background: url("../../assets/openAccount/union1.svg");
        background-repeat: no-repeat;
      }
      span::before {
        content: "";
        position: absolute;
        top: -60px;
        right: 136px;
        width: 40px;
        height: 48px;
        background: url("../../assets/openAccount/2.png");
        background-repeat: no-repeat;
      }
    }
    .openAccount-step-block-three {
      width: 317px;
      text-align: center;
      margin-bottom: 120px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -61px;
        right: 240px;
        width: 242px;
        height: 189px;
        background: url("../../assets/openAccount/union.svg");
        background-repeat: no-repeat;
      }
      &::after {
        content: "";
        position: absolute;
        top: -51px;
        right: -136px;
        width: 164px;
        height: 166px;
        background: url("../../assets/openAccount/invoice.png");
        background-repeat: no-repeat;
      }
      span::before {
        content: "";
        position: absolute;
        top: -60px;
        right: 136px;
        width: 40px;
        height: 48px;
        background: url("../../assets/openAccount/3.png");
        background-repeat: no-repeat;
      }
    }
    .openAccount-step-block-four {
      width: 317px;
      text-align: center;
      margin-bottom: 120px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -43px;
        right: 250px;
        width: 242px;
        height: 189px;
        background: url("../../assets/openAccount/credit-card.png");
        background-repeat: no-repeat;
      }
      &::after {
        content: "";
        position: absolute;
        top: -65px;
        right: -136px;
        width: 240px;
        height: 242px;
        background: url("../../assets/openAccount/union1.svg");
        background-repeat: no-repeat;
      }
      span::before {
        content: "";
        position: absolute;
        top: -60px;
        right: 136px;
        width: 40px;
        height: 48px;
        background: url("../../assets/openAccount/4.png");
        background-repeat: no-repeat;
      }
    }
    .openAccount-step-block-five {
      width: 317px;
      text-align: center;
      position: relative;
      //   &::before {
      //     content: "";
      //     position: absolute;
      //     top: -61px;
      //     right: 240px;
      //     width: 242px;
      //     height: 189px;
      //     background: url("../../assets/openAccount/union.svg");
      //     background-repeat: no-repeat;
      //   }
      &::after {
        content: "";
        position: absolute;
        top: -40px;
        right: -180px;
        width: 164px;
        height: 108px;
        background: url("../../assets/openAccount/money.png");
        background-repeat: no-repeat;
      }
      span::before {
        content: "";
        position: absolute;
        top: -60px;
        right: 136px;
        width: 40px;
        height: 48px;
        background: url("../../assets/openAccount/5.png");
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
