export default {
  text: 'Открыть счет',
  text1: 'Услуги',
  text2: 'Корпоративный',
  text3: 'Связаться',
  text4: 'Интернет-банкинг',
  text5: 'Регистрация',
  text6: 'CLEVELAND BANK',
  text7: 'CLEVELAND BANK – БАНК, КОТОРОМУ МОЖНО ДОВЕРЯТЬ',
  text8: 'Свяжитесь с нами',
  text9: ' ПРОВЕРЯЙТЕ СВОЙ БАЛАНС, ОСУЩЕСТВЛЯЙТЕ СДЕЛКИ, ПОЛУЧАЙТЕ ВЫГОДУ, НЕ ВЫХОДЯ ИЗ ДОМА',
  text10: 'Войти',
  text11: 'МЕЖДУНАРОДНЫЙ ПРЯМОЙ IBAN',
  text12: 'Личный IBAN предназначен для клиентов, которые ведут международную деятельность. Данное решение позволяет работать по всему миру в соответствии с международной платежной системой.',
  text13: 'Это позволяет получать деньги из разный частей мира.',
  text14: 'Требования по минимальному депозиту составляют 5,000 евро.',
  text15: 'Открытие данного вида счета занимает 14-21 банковских дней.',
  text16: 'ЛИЧНЫЙ МЕНЕДЖЕР',
  text17: 'Наши высококвалифицированные специалисты всегда помогут Вам и предоставят всю необходимую информацию. Вы получите номер менеджера, который Вам окажет помощь в любое время.',
  text18: 'БЫСТРОЕ ОТКРЫТИЕ СЧЕТА',
  text19: 'Ваш счет будет открыт на высокотехнологичной платформе с максимальной скоростью.',
  text20: 'Свяжитесь с нами',
  text21: 'Наши преимущества:',
  text22: ' Отсутствие CRS: Common Reporting Standard (CRS) - это информационный стандарт для автоматического обмена информацией (AEOI) о международных банковских счетах между налоговыми органами. Обратите внимание, что Западная Сахара является спорной территорией и не отвечает стандартам CRS. Поэтому Вы можете быть уверены, что Ваша личная информация будет в безопасности.',
  text23: 'Вы получаете высокую конфиденциальность и безопасность: наша система полностью защищена для передачи частной и корпоративной информации, что гарантирует незамедлительное выполнение бизнес-операций.',
  text24: 'Нет письменным распоряжениям: “Поиск активов с использованием письменных распоряжений» сообщает, что национальный суд может выдать форму письма (также известную как «письмо с запросом» или «запрос о правовой помощи»), чтобы заставить иностранный банк раскрыть данные об офшорных счетах',
  text25: 'О CLEVELAND BANK',
  text26: ' Высококвалифицированные сотрудники нашего банка всегда готовы предоставить Вам лучший сервис. Счета Cleveland Bank надежно защищены. Хранение денег и удовлетворение потребностей клиента - вот основные цели Cleveland Bank.',
  text27: 'Узнать больше',
  text28: '100% Личного удовлетворения',
  text29: 'Системы с высоким уровнем защиты ',
  text30: 'Личный менеджер (24/7)',
  text31: 'Вы можете положиться на нас',
  text32: 'Работаем со всеми юрисдикциями',
  text33: 'Открыть счет',
  text34: 'Услуги',
  text35: 'Корпоративный',
  text36: 'Контакты',
  text37: 'Телефон: +9718000321116',
  text38: 'eMail:  info@clevelandinterbank.com',
  text39: 'Номер банковской лицензии: 159 20/ CIBL Central Reserve Authority of SADR',
  text40: 'Cleveland Bank © 2020 / Все права защищены ',
  text41: ' CLEVELAND BANK ОТКРЫВЕТ СЧЕТА ДЛЯ КОРПОРАТИВНЫХ КЛИЕНТОВ И ЧАСТНЫХ ЛИЦ',
  text42: 'Как открыть счет:',
  text43: 'шаг',
  text44: 'Вам необходимо отправить своему персональному менеджеру копии всех необходимых документов для открытия счета.',
  text45: 'Вы ждете 3-5 дней, чтобы Compliance проверил документы.',
  text46: 'шаг',
  text47: 'шаг',
  text48: 'Вы получите письмо об открытии счета по электронной почте, где Вы сможете найти данные для входя в интернет-банкинг.',
  text49: 'Счет полностью готов к использованию.',
  text50: 'шаг',
  text51: '* Субсчета в валютах, отличных от евро, теперь могут быть открыты по запросу клиента.',
  text52: 'Свяжитесь с нами',
  text53: 'НАШИ КЛИЕНТЫ',
  text54: 'Наши корпоративные банковские счета предлагают все, что Вы можете ожидать от международного частного банка. Ваш личный менеджер будет отслеживать все операции и в любое время Вы всегда можете ему позвонить.',
  text55: '— Anna Shnikker, аккаунт менеджер',
  text56: 'Наши преимущества',
  text57: 'Наш банк чувствителен к требованиям рынка и стремится поддерживать новые «точки роста» своим присутствием. И, конечно же, мы строим новые планы, которые достаточно амбициозны, но имеют прочную основу, состоящую из четких целей, знаний методов их достижения и сплоченности всей команды.',
  text58: ' Мы преодолели множество трудностей, но в любой ситуации клиент всегда был для нас главной ценностью, а его доверие - высшим достижением. Это величайшая ценность, на которой строится успех каждого банка.',
  text59: 'Мы растем вместе с клиентами. Мы повышаем свою финансовую грамотность, предлагаем удобные и востребованные  продукты и услуги, принимаем индивидуальные решения.',
  text60: 'Мы постоянно совершенствуем банковские услуги и продукты, но одно остается неизменным - оперативность, честность и надежность.',
  text61: ' Наши персональные менеджеры всегда готовы решить любую возникшую проблему.',
  text62: ' Cleveland Bank имеет в своем распоряжении опытных экспертов, современные технологии, надежность и безопасность.',
  text63: 'ДЕНЕЖНЫЕ ПЕРЕВОДЫ',
  text64: ' Вы можете осуществлять денежные переводы по всему миру. Это займет 1-3 рабочих дня. Мы выбрали самые быстрые и надежные способы для денежных переводов. В случае крупных переводов следует уведомить личного менеджера.',
  text65: ' ДЕПОЗИТ НАЛИЧНЫМИ',
  text66: 'Депозит наличными происходит в наиболее удобном для клиента месте. Есть возможность использовать банкомат ',
  text67: 'КРИПТО БИЗНЕС',
  text68: 'Cleveland bank - один из немногих банков, принимающих криптовалюту. Наш банк может менять и обменивать не только фиатную валюту, но и криптовалюту. Это связано с тем, что большинство оффшорных банков блокируют переводы с биржи на Ваш счет. Часто происходит, что, если Вы отправляете перевод  с крипто биржи, банки закрывают ваш счет. Каждый клиент биржи, желающий совершить транзакцию через банк, должен открыть банковский счет. Затем биржа может открыть корпоративный и клиентский счет в оффшорном банке.',
  text69: 'Клиенты биржи смогут отправлять переводы со своего биржевого счета на свой офшорный банковский счет. То есть клиенты могут проводить транзакции только с криптосчета от своего имени (или от имени оффшорной компании) и с банковского счета с тем же именем.',
  text70: 'Каждый клиент биржи, желающий совершить операцию через банк, должен открыть счет в банке. Затем биржа может открыть корпоративный и клиентский счет в оффшорном банке.',
  text71: 'Клиентам биржи будет разрешено отправлять переводы со своего биржевого счета на свой офшорный банковский счет. Другими словами, клиенты могут совершать транзакции только с крипто-счета на свое имя (или название своей оффшорной компании) и на банковский счет с тем же именем.',
  text72: 'Пожалуйста, свяжитесь с нами для получения дополнительной информации',
  text73: 'МЕЖДУНАРОДНЫЕ ЧЕКИ',
  text74: 'Время проведения операции от 10 до 40 банковских дней.',
  text75: 'Свяжитесь с нами',
  text76: 'ИНВЕСТИЦИИ',
  text77: ' Дебетовые карты, сберегательные счета, онлайн-банкинг, криптовалюта и другие услуги доступны в Cleveland Bank. Свяжитесь с нами, чтобы получить дополнительную информацию',
  text78: 'Свяжитесь с нами',
  text79: 'КОРПОРАТИВНЫЙ',
  text80: ' Cleveland International Bank обслуживает своих клиентов на протяжении многих лет и до сих пор имеет прекрасную репутацию в сфере услуг. Наш банк стремился соблюдать все правила и положения, установленные руководящими органами страны, которые предоставили своим клиентам безопасный банковский опыт, а также все функции и преимущества оффшорного банка.',
  text81: 'Банк работал по лицензии, предоставленной Северным Кипром Турецкой Республики. Однако с целью повышения безопасности и улучшения качества услуг для своих клиентов Cleveland International Bank пришлось искать альтернативную юрисдикцию. После тщательного поиска и изучения лучших альтернатив для проведения всех оффшорных банковских операций было принято решение получить новую лицензию для продолжения бесперебойной деятельности банка. В связи с чем Cleveland International Bank получил лицензию в новой юрисдикции Западной Сахары.',
  text82: 'Cleveland International Bank теперь пользуется всеми преимуществами легального банка и может беспрепятственно обслуживать своих клиентов в новой юрисдикции.',
  text83: 'КЛИЕНТООРИЕНТИРОВАННОСТЬ',
  text84: 'Клиентоориентированность нашего банка подразумевает создание и развитие системы качественного обслуживания, основанной на тесном взаимодействии менеджеров банка с клиентами.',
  text85: 'Чем отличается наш банк от других банков:',
  text86: 'Доступность клиентской политики',
  text87: 'Деятельность, направленная на постоянное привлечение новых клиентов.',
  text88: 'Наличие конкурентоспособных бизнес-технологий для работы с клиентами на рабочем месте.',
  text89: 'Проведение постоянной оценки качества обслуживания клиентов посредством опросов и мониторинга',
  text90: 'Сегментация клиентской базы',
  text91: 'Развитие взаимовыгодных отношений с клиентами путем оказания помощи клиентам в  коммерческой и иной деятельности. ',
  text92: 'Результатом развития таких отношений является создание партнерских банковских отношений, предполагающих взаимопонимание.',
  text93: 'Формирование партнерских отношений в результате клиентоориентированной политики значительно улучшает качество банковских услуг. Качество банковских услуг всегда становится решающим фактором при выборе банка. При низком рейтинге банка, чтобы сохранить свои прежние позиции, процентные ставки и другие услуги банка должны кардинально отличаться от конкурентов, а это практически невозможно в современной банковской системе. Поэтому проведение качественной клиентоориентированной политики для Cleveland Bank является необходимым фактором успешного развития.',
  text94: 'КОНФИДЕНЦИАЛЬНОСТЬ И БЕЗОПАСНОСТЬ',
  text95: ' В целях предотвращения мошеннических действий третьих лиц, в том числе в связи с частыми случаями мошенничества личных страниц в социальных сетях и входящих запросов под различными правдоподобными предлогами для передачи личных данных, мы напоминаем о правилах безопасной использование банковской платежной карты при проведении операций в сети Интернет.',
  text96: ' Реквизиты карты (номер, срок действия, код CVV, пароль 3D-Secure), а также логин и пароль для входа в систему Интернет-банкинг являются личной информацией и не подлежат передаче третьим лицам, включая родственников, друзей и знакомых.',
  text97: 'Не храните информацию о Вашей банковской карте в Интернете и не разрешайте интернет-магазинам хранить данные Вашей карты. Ограничивая таким образом объем информации, хранящейся в магазине, Вы меньше пострадаете, если мошенники проникнут в базу данных магазина.',
  text98: 'Наш банк рекомендует устанавливать и регулярно обновлять антивирусное ПО на Ваших устройствах: компьютерах, ноутбуках, планшетах, телефонах.',
  text99: 'При получении подозрительных писем обратите внимание на электронный адрес отправителя письма. Если сомневаетесь, позвоните в наш контакт-центр.',
  text100: ' Если у Вас есть возможность - приобретите отдельную карту специально для расчетов в Интернете, установите на ней небольшой лимит транзакций и вносите деньги на свой счет только для плановой транзакции.',
  text101: 'К карте рекомендуется подключить услугу SMS-оповещения и 3D Secure. Оперативное получение клиентом информации о транзакциях, проводимых с использованием банковской платежной карты, значительно снижает риск мошеннических операций по ней и позволяет контролировать любые расходные операции с банковской платежной картой в режиме реального времени.',
  text102: 'Человеческое вмешательство',
  text103: 'Специалисты нашего банка всегда осуществляют проверку счетов. Ручная проверка позволяет обеспечить конфиденциальность и безопасность наших клиентов.',
  text104: 'Лучшее в отрасли шифрование',
  text105: ' Наши IT-специалисты установили программное обеспечение, которое полностью обезопасит все счета.',
  text106: 'Только транзакционные банковские операции',
  text107: 'Не каждый знает, что самый популярный вид мошенничества – это осуществление онлайн операций и банковская система с частичным резервированием. С Cleveland bank вы можете быть спокойны, поскольку у нас 100% коэффициент возврата депозитов.',
  text108: 'Свяжитесь с нами',
  text109: 'Имя',
  text110: 'E-Mail',
  text111: 'Телефон',
  text112: 'Наименование компании ',
  text113: 'Страна',
  text114: 'Сообщение',
  text115: 'Отправить',
  text116: 'НАШИ ОФИСЫ',
  text117: 'Наш офис в Танзании',
  text118: 'LETISIA TOWER, Plot 59C,',
  text119: 'KIJITONYAMA, NEW BAGAMOYO ROAD, DAR ES SALAAM, TANZANIA (Mon-Fri, 10.00 am till 7.00 pm)',
  text120: 'Чтобы войти в интернет-банкинг введи Ваш ID и пароль',
  text121: 'Логин',
  text122: 'Отправить',
  text123: 'Чтобы подать заявку на открытие банковского счет, пожалуйста, заполните форму ниже',
  text124: 'Имя',
  text125: 'Фамилия',
  text126: 'Страна',
  text127: 'Город',
  text128: 'Адрес',
  text129: 'Индекс',
  text130: 'Номер телефона',
  text131: 'E-mail',
  text132: 'Пароль',
  text133: 'Подтвердите пароль',
  text134: 'Отправит',
  text135: 'Отменить',
  text136: 'Сборы',
  text137: 'Сборы и условия',
  text138: 'Мы стремимся к тому, чтобы наши ставки и сборы были ясными и понятными, чтобы вам было проще проводить сравнения, а также принимать обоснованные решения, когда дело касается ваших денег.',
  text139: 'Чтобы узнать больше, свяжитесь с нами!',
}
