<template>
  <div>
    <CustomDialog
      :visible="showLoginDialog"
      :title="this.lang.text120"
      :cancelBtn="this.lang.text122"
      :confirmBtn="confirmBtnText"
      @click="$router.push('/personal-account')"
      @confirm="signIn"
      @close="closeDialog"
      :loadingConfirmButton="loadingConfirmButton"
      width="400px"
    >
      <template v-slot:body>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <div v-if="!isPinMode" class="login-wrapper">
          <el-input
            placeholder="Enter your login"
            v-model="login"
            class="input"
          ></el-input>
          <el-input
            placeholder="Enter your password"
            type="password"
            v-model="password"
            class="input"
          />
        </div>
        <div v-if="isPinMode" class="login-wrapper">
          <el-input placeholder="Enter your pin" v-model="pin" class="input" />
        </div>
      </template>
    </CustomDialog>

    <SignUpDialog
      @close="showSignUpDialog = false"
      :showSignUpDialog="showSignUpDialog"
    />

    <header class="nav">
      <div class="nav__wraper wraper">
        <nav class="nav__navigation">
          <div class="nav__logo-block d-flex-rn flex-space-between">
            <img src="..\..\assets\logo.svg" alt="logo" class="logo-img" />
            <div v-if="!isPersonalAccountPage" class="nav__btn-block d-flex-rn">
              <div class="nav__btn btn-orange">
                <el-button
                  v-if="!isAuth"
                  type="primary"
                  @click="showLoginDialog = true"
                  >E-Banking</el-button
                >
                <el-button
                  v-if="isAuth"
                  @click="$router.push('/personal-account')"
                  >Account</el-button
                >
              </div>
              <div class="nav__btn btn-purple">
                <el-button
                  type="warning"
                  v-if="!isAuth"
                  @click="showSignUpDialog = true"
                  >Registration</el-button
                >
              </div>
              <div class="menu__language">
                <el-select
                  @change="onChangeLang"
                  v-model="value"
                  placeholder="EN"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-else class="nav__btn-block-bank">
              <div class="menu__user">
                <div v-if="isAuth" class="header_links">
                  <span>{{ userFullName }}</span>
                </div>
                <el-select
                  @change="onChangeLang"
                  v-model="value"
                  placeholder="EN"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div class="btn-out" @click="logout()">Out</div>
              </div>
            </div>
          </div>
          <div v-if="isApi" class="nav__list-block d-flex-rn flex-end-center">
            <ul class="nav__link-block d-flex-rn">
              <li>
                <span
                  class="link-block__item"
                  v-if="isApi"
                  @click="$router.push('/')"
                  >Back</span
                >
              </li>
            </ul>
          </div>
          <div
            v-else-if="!isPersonalAccountPage"
            class="nav__list-block d-flex-rn flex-end-center"
          >
            <ul class="nav__link-block d-flex-rn" id="app">
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-one'">About</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-two'">Our benefits</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-three'"
                  >How to open an account</a
                >
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-four'">Services</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-five'">Bank security</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-six'">Corporate</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-seven'">Fees</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-nine'">API Guide</a>
              </li>
              <li class="link-block__item">
                <a href="" v-scroll-to="'#section-eight'">Contact</a>
              </li>
            </ul>
          </div>
          <div v-else class="nav__list-block d-flex-rn flex-end-center">
            <ul class="nav__link-block d-flex-rn">
              <li>
                <span
                  class="link-block__item"
                  v-if="isAuth"
                  @click="$router.push('/')"
                  >Back</span
                >
              </li>
            </ul>
          </div>
        </nav>
        <nav class="nav__navigation nav-mobile">
          <div class="hamburger-menu">
            <input id="menu__toggle" type="checkbox" />
            <label class="menu__btn" for="menu__toggle">
              <span></span>
            </label>
            <div class="nav-mobile__block d-flex-rn">
              <p></p>
              <p class="nav-mobile__text">Cleveland Bank</p>
              <a href="#" class="nav-mobile__login"> </a>
              <div
                v-if="!isPersonalAccountPage"
                class="nav__btn-block d-flex-rn"
              >
                <div class="nav__btn btn-orange">
                  <el-button
                    v-if="!isAuth"
                    type="primary"
                    @click="showLoginDialog = true"
                    >E-Banking</el-button
                  >
                  <el-button
                    v-if="isAuth"
                    @click="$router.push('/personal-account')"
                    >Account</el-button
                  >
                </div>
                <!-- <div class="nav__btn btn-purple">
                  <el-button
                    type="warning"
                    v-if="!isAuth"
                    @click="showSignUpDialog = true"
                    >Registration</el-button
                  >
                </div> -->
                <!-- <div class="menu__language">
                  <el-select
                    @change="onChangeLang"
                    v-model="value"
                    placeholder="EN"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div> -->
              </div>
            </div>
            <ul class="menu__box">
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-one'"
                  >About</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-two'"
                  >Our benefits</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-three'"
                  >How to open an account</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-four'"
                  >Services</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-five'"
                  >Bank security</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-six'"
                  >Corporate</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-seven'"
                  >Fees</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-nine'"
                  >API Guide</a
                >
              </li>
              <li>
                <a class="menu__item" href="#" v-scroll-to="'#section-eight'"
                  >Contact</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDialog from "../customDialog";
import SignUpDialog from "../createAccountDialog";

export default {
  data() {
    return {
      options: [
        {
          value: "en",
          label: "en",
        },
        {
          value: "ru",
          label: "ru",
        },
      ],
      showLoginDialog: false,
      login: "",
      password: "",
      errorMessage: "",
      loadingConfirmButton: false,
      isPinMode: false,
      dialogTitle: "To enter online banking write your ID and password",
      confirmBtnText: "Login",
      showSignUpDialog: false,
      pin: null,
      value: "",
    };
  },

  methods: {
    onChangeLang(value) {
      this.$store.commit("setLang", value);
    },
    closeDialog() {
      this.showLoginDialog = false;
      this.isPinMode = false;
    },
    async signIn() {
      if (this.loadingConfirmButton === true) return;

      if (this.isPinMode) {
        this.confirmPin();
        return;
      }
      if (!this.login.trim() || !this.password.trim()) {
        this.errorMessage = "Invalid login or password";
        return;
      }

      this.loadingConfirmButton = true;
      await this.$store
        .dispatch("signIn", {
          username: this.login,
          password: this.password,
        })
        .then((response) => {
          if (response.code) throw response.message;
          this.isPinMode = true;
          sessionStorage.setItem("token", response.token);
          this.$store.commit("setUserName", this.login);
          this.$store.dispatch("requestPinCode", response.token);
          this.confirmBtnText = "Confirm";
          this.dialogTitle = "Enter the pin we sent you in the mail";
        })
        .catch((error) => {
          this.errorMessage = error;
          this.loadingConfirmButton = false;
        });

      this.loadingConfirmButton = false;
    },

    confirmPin() {
      this.$store
        .dispatch("sendPin", this.pin)
        .then((response) => {
          if (response === "PIN IS NOT VALID") {
            this.errorMessage = "PIN IS NOT VALID";
          } else {
            this.$store.commit("setUser", response);
            this.showLoginDialog = false;
            this.confirmBtnText = "Login";
            this.isPinMode = false;
            this.dialogTitle =
              "To enter online banking write your ID and password";
            this.errorMessage = "";
          }
        })
        .catch(() => {
          this.errorMessage = "Invalid pin";
        })
        .finally(() => {
          this.loadingConfirmButton = false;
        });
    },
    logout() {
      this.$router.push("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      window.location.reload();
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    userFullName() {
      return this.$store.getters.fullName;
    },
    isAuth() {
      return !!this.$store.getters.user;
    },
    isPersonalAccountPage() {
      return [
        "home",
        "details",
        "transactions",
        "internationalTransfer",
        "intraTransfer",
      ].includes(this.$route.name);
    },
    isApi() {
      return ["apiGuide"].includes(this.$route.name);
    },
  },

  components: {
    CustomDialog,
    SignUpDialog,
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 181px;
  background: #fff;
}
.nav__navigation {
  width: 100%;
  height: 100%;
  padding-top: 33px;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: auto;
  background: #fff;
}
.nav__btn {
  padding-right: 45px;
}
.btn-orange {
  .el-button {
    background: linear-gradient(
      90deg,
      #fdc006 0%,
      #fdc006 0.01%,
      #f99300 94.81%
    );
    border: none;
    color: #fff;
    width: 135px;
    height: 45px;
    transition-duration: 1s;
    &:hover {
      background: #f78989;
      transition-duration: 1s;
    }
  }
}
.btn-out {
  background: linear-gradient(90deg, #fdc006 0%, #fdc006 0.01%, #f99300 94.81%);
  cursor: pointer;
  border: none;
  color: #fff;
  width: auto;
  height: auto;
  padding: 5px;
  border-radius: 5px;
  transition-duration: 1s;
  &:hover {
    background: #f78989;
    transition-duration: 1s;
  }
}

.btn-purple {
  .el-button {
    background: linear-gradient(90deg, #4356be -12.94%, #414769 100%), #414769;
    color: #fff;
    width: 135px;
    height: 45px;
    border: none;
    transition-duration: 1s;
    &:hover {
      background: #66b1ff;
      transition-duration: 1s;
    }
  }
}
.nav__btn:first-child {
  padding-right: 30px;
}
.nav__list-block {
  padding-right: 150px;
  padding-left: 150px;
  padding-bottom: 50px;
}
.nav__logo-block {
  height: 100%;
  padding-right: 150px;
  padding-left: 150px;
}
.menu__language {
  width: 80px;
  ::-webkit-input-placeholder {
    color: #000;
    font-size: 14px;
    font-family: Roboto;
  }
  :-ms-input-placeholder {
    color: #000;
    font-size: 14px;
    font-family: Roboto;
  }
  ::-ms-input-placeholder {
    color: #000;
    font-size: 14px;
    font-family: Roboto;
  }
  ::placeholder {
    color: #000;
    font-size: 14px;
    font-family: Roboto;
  }
}
.logo-img {
  width: auto;
  height: auto;
  margin-right: 13px;
}
.logo-text {
  font-size: 11px;
  line-height: 14px;
  color: #b1b1b1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}
.nav__link-block {
  list-style-type: none;
  margin-top: 30px;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.link-block__item {
  font-size: 20px;
  color: #000;
  margin-right: 30px;
  transition-duration: 1s;
  font-family: Roboto;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    height: 40px;
    height: 5px;
    background: #414769;
    transition: all 0.2s linear;
    width: 0;
    bottom: 0;
  }
  &:hover {
    color: #414769;
    transition-duration: 1s;
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      right: 0px;
      background: #414769;
      width: 100%;
      height: 5px;
      border-radius: 5px;
    }
  }
}
.link-block__item:last-child {
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
.nav-mobile {
  display: none;
  width: 100vw;
}

/* мобильное меню */
#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}
.hamburger-menu {
  height: 75px;
}
.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 33px;
  left: 20px;
  width: 29px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.nav-mobile__block {
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  margin-right: 20px;
  margin-left: 20px;
}
.nav-mobile__text {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #b1b1b1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ff7f36;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;

  width: 300px;
  height: 100%;

  margin: 0;
  padding: 80px 0;

  list-style: none;

  background-color: #202224;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);

  transition-duration: 0.25s;
}

.menu__item {
  display: block;
  padding: 12px 24px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #cfd8dc;
}

@media (max-width: 920px) {
  .nav__navigation {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .link-block__item {
    margin-right: 25px;
  }
  .nav {
    height: 95px;
  }
}
@media (max-width: 800px) {
  .nav__navigation {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
}
@media (max-width: 480px) {
  .nav-mobile__text {
    font-size: 12px;
    width: 151px;
  }
  .nav-mobile__block {
    padding-top: 0;
  }
}
.login-wrapper {
  .el-input {
    border: solid 2px #c4c4c4;
    margin-bottom: 10px;
  }
}
.menu__user {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-select {
    width: 80px;
  }
  .header_links {
    span {
      position: relative;
      margin-left: 30px;
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: -22px;
        background: url("../../assets/user/user-icon.svg");
      }
    }
  }
}
</style>
