<template>
  <div class="contact" >
    <div class="contact-wrapper">
      <div class="contact-content flex-space-between-str">
        <div class="contact-block">
          <h2 class="title">CONTACT</h2>
          <p class="text">Our phone</p>
          <ul class="list">
            <li>
              <a class="text" href="tel:+9718000321116" target="_blank"
                >+ 9718000321116</a
              >
              <span class="icon">
                <a href="" target="_blank"
                  ><img src="..\..\assets\contact\phone.svg" alt=""
                /></a>
              </span>
            </li>
            <li>
              <a class="text" href="https://wa.me/37257390877" target="_blank"
                >+37257390877</a
              >
              <span class="icon">
                <a href="https://wa.me/37257390877" target="_blank"
                  ><img src="..\..\assets\contact\whatsapp.svg" alt=""
                /></a>
              </span>
            </li>
            <li class="text">
              <a class="text" href="https://t.me/ClevelandBank" target="_blank"
              >@ClevelandBank</a
              >
              <span class="icon">
                <a href="https://t.me/ClevelandBank" target="_blank"
                ><img src="..\..\assets\contact\telegram.svg" alt=""
                /></a>
              </span>
            </li>
          </ul>
          <div>
            <p class="text">Our mail</p>
            <p class="text position">
              <a class="text" href="mailto:info@clevelandibu.com"
                >info@clevelandibu.com</a
              >
              <span class="icon">
                <a href="">
                  <img src="..\..\assets\contact\internet.svg" alt="" />
                </a>
              </span>
            </p>
          </div>
        </div>
        <div class="office-block">
          <div class="dubai-block">
            <p class="text">Our Dubai office</p>
            <p class="text">18th Floor, Burlington Tower - Marasi Dr - Dubai</p>
          </div>
          <div>
            <p class="text">Our Estonian office</p>
            <p class="text">
              Harju 6 10130 Tallinn, Estonia
            </p>
          </div>
          <div>
            <p class="text">Registered office</p>
            <p class="text">
              Navarra Hospital Building office 1, Tifariti, SADR
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background: #d9deff;
  position: relative;
  padding: 80px 20px;
}
.contact-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
}

.office-block div {
  width: 80%;
  &:nth-last-child(2) {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  text-align: left;
  margin: 0;
  padding-bottom: 70px;
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
  text-decoration: none;
}
.list {
  padding-bottom: 50px;
  text-align: left;
  li {
    display: flex;
  }
}
.icon {
  padding-left: 10px;
}
.position {
  display: flex;
  align-items: center;
}

.dubai-block {
  margin-top: 0;
}

@media screen and (max-width: 1200px) {
  .text {
    font-size: 18px;
  }
}
</style>
