import Vue from "vue";
import Vuex from "vuex";
import en from "../translates/en";
import ru from "../translates/ru";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    server: "https://apicl.webproject.fyi:8080",
    userName: null,
    pinCode: null,
    user: null,
    lang: "en",
    accountTransactions: [],
  },

  getters: {
    fullName(state) {
      const { user } = state;

      if (!user) return "";

      return `${user.client_last_name} ${user.client_first_name}`;
    },
    user(state) {
      return state.user;
    },
    accountDetails(state) {
      if (!state.user) return [];

      return state.user.accounts;
    },
    accountTransactions(state) {
      console.log(state.accountTransactions);
      return state.accountTransactions.map((el) => {
        return {
          ...el,
          label: `${el.currency} ${
            state.user.accounts.find((acc) => {
              return acc.account_special_number === el.account_number;
            }).account_type_name
          }`,
        };
      });
    },
    lang(state) {
      return state.lang === "en" ? en : ru;
    },
    allTransactions(state) {
      let allTransactions = [];
      state.accountTransactions.forEach((el) => {
        allTransactions.push(...el.transaction);
      });

      return allTransactions;
    },
  },
  mutations: {
    setUserName(state, username) {
      state.userName = username;
    },

    setUser(state, user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },

    setAccountTransactions(state, accountTransactions) {
      state.accountTransactions = accountTransactions;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    signIn({ state }, params) {
      return fetch(`${state.server}/api/login_check`, {
        method: "POST",
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    changePassword({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/changes/passwords`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    requestPinCode({ state }, token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/sends/users/pins`, {
        method: "POST",
        headers,
      })
        .then((response) => response.json())
        .then((response) => {
          state.pinCode = response[1];
          return response[1];
        });
    },

    sendPin({ state }, pin) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/user/verifications`, {
        method: "POST",
        body: JSON.stringify({ pin }),
        headers,
      }).then((response) => response.json());
    },

    getAccountTransaction({ state, commit }) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(
        `${state.server}/api/transfeers/gets/users/accounts/details`,
        {
          method: "POST",
          headers,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          commit("setAccountTransactions", data);
        });
    },
    sendInterTransfer({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(
        `${state.server}/api/transfeers/payments/requests/applications`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(params),
        }
      ).then((response) => response.json());
    },

    sendRefundTransfer({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(
        `${state.server}/api/transfeers/refunds/shops/transactions`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(params),
        }
      ).then((response) => response.json());
    },

    sendIntraTransfer({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/api/transfeers/internals/applications`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    sendMessage({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/mailer/contacts/forms`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    signUp({ state }, params) {
      const token = sessionStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${state.server}/mailer/onlines/registrations`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    // eslint-disable-next-line no-unused-vars
    sendContactForm({state}, params) {
      const headers = {
        "Content-Type": "application/json",
      };
      return fetch(`https://assembly.tehnik.tech/api/send_form/from`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },
  },
});

export default store;
