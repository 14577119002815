<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">
            <h1>CLEVELAND BANK – THE BANK YOU CAN TRUST</h1>
            <div class="nav__btn btn-orange" id="section-one">
              <el-button v-scroll-to="'#section-eight'">Contact us</el-button>
            </div>
          </div>
        </div>
      </div>
      <video
        loop
        muted
        autoplay
        poster="..\..\assets\video\posterVideo.jpg"
        class="fullscreen-bg__video"
      >
        <source src="..\..\assets\video\videoBG.mp4" type="video/mp4" />
        <source src="video/plane.webm" type="video/webm" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.overlay h1 {
  font-family: Montserrat-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #ffffff;
  text-align: left;
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blur__block {
  max-width: 1170px;
  width: 100%;
  height: 359px;
  left: 375px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  margin: 0 auto;
}
.blur__content {
  padding-left: 71px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.btn-orange {
  text-align: left;
  .el-button {
    background: linear-gradient(
      90deg,
      #fdc006 0%,
      #fdc006 0.01%,
      #f99300 94.81%
    );
    color: #fff;
    width: 135px;
    height: 45px;
    border: none;
    transition-duration: 1s;
    &:hover {
      background: #f78989;
      transition-duration: 1s;
    }
  }
}
.fullscreen-bg {
  overflow: hidden;
  //   z-index: -100;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 420px;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.overlay {
  background: rgba(29, 0, 89, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg {
    // background: url("../images/plane.jpg") center center / cover no-repeat;
  }

  .fullscreen-bg__video {
    display: none;
  }

  .overlay h1 {
    font-size: 27px;
  }

  .blur__content {
    padding: 20px;
  }
}

/**/
.backToSitehereBtn {
  display: block;
  width: 150px;
  height: 150px;
  background: #01bc78;
  text-align: center;
  line-height: 170px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  text-decoration: none;
  top: -40px;
  left: -40px;
  transform: rotate(-45deg) scale(1);
  transition: all 0.25s linear;
}

.backToSitehereBtn:hover {
  text-decoration: none;
  top: -10px;
  left: -10px;
  transform: rotate(-45deg) scale(1.3);
}
</style>
