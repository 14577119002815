<template>
  <div class="details-wrapper">
    <div class="user-wrapper">
      <h3>Client details:</h3>
      <div class="user">
        <span class="user__key text">Client ID: </span>
        <span class="user__value text">{{ user.client_id }}</span>
      </div>
      <div class="user">
        <span class="user__key text">Client name: </span>
        <span class="user__value text">{{
          `${user.client_last_name} ${user.client_first_name}`
        }}</span>
      </div>
      <div class="user">
        <span class="user__key text">Company Name: </span>
        <span class="user__value text">{{ user.company_name }}</span>
      </div>
      <div class="user">
        <span class="user__key text">Contact details: </span>
        <span class="user__value text">{{ user.email }}</span>
      </div>
    </div>
    <div class="change-password">
      <h3>Change password</h3>
      <span v-if="!firstInpError" class="change-password__label text"
        >Current password</span
      >
      <span v-if="firstInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Enter your current password"
        v-model="currentPassword"
        class="change-password__input"
        type="password"
        @input="firstInpError = false"
      />
      <span v-if="!secondInpError" class="change-password__label text"
        >New password</span
      >
      <span v-if="secondInpError" class="error-message">{{
        errorMessage
      }}</span>
      <el-input
        placeholder="Enter your new password"
        v-model="newPassword"
        class="change-password__input"
        type="password"
        @input="
          secondInpError = false;
          notMatch = false;
        "
      />
      <span v-if="!thirdInpError" class="change-password__label text"
        >Confirm password</span
      >
      <span v-if="thirdInpError" class="error-message">{{ errorMessage }}</span>
      <el-input
        placeholder="Confirm your new password"
        v-model="repeatNewPassword"
        class="change-password__input"
        type="password"
        @input="
          thirdInpError = false;
          notMatch = false;
        "
      />
      <span v-if="notMatch" class="error-message">
        The new password and password confirmations do not match
      </span>
      <span v-if="successful" class="success-message">
        Password changed successfully
      </span>
      <el-button @click="changePassword" type="primary" class="change-button">
        CHANGE
      </el-button>
    </div>
    <DetailsTable />
  </div>
</template>

<script>
import DetailsTable from "./detailsTable";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      loadingChangeBtn: true,
      errorMessage: "The password contains less than 8 characters",
      firstInpError: false,
      secondInpError: false,
      thirdInpError: false,
      notMatch: false,
      successful: false,
    };
  },

  methods: {
    async changePassword() {
      const { currentPassword, newPassword, repeatNewPassword } = this;

      if (currentPassword.length < 8) this.firstInpError = true;
      if (newPassword.length < 8) this.firstInpError = true;
      if (repeatNewPassword.length < 8) this.firstInpError = true;
      if (this.firstInpError || this.secondInpError || this.thirdInpError)
        return;
      if (newPassword !== repeatNewPassword) {
        this.notMatch = true;
        return;
      }

      await this.$store
        .dispatch("changePassword", {
          oldPassword: currentPassword,
          newPassword,
        })
        .then(() => {
          this.successful = true;
        });
    },
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/");
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  components: {
    DetailsTable,
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-radius: 10%;
  padding: 60px;
  margin-top: 70px;
  margin-bottom: 70px;

  h3 {
    font-family: Montserrat-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    text-transform: uppercase;
    color: #000000;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}
.text {
  font-family: Montserrat-Medium;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.user {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &__key {
    display: block;
    margin-right: 30px;
  }
}

.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 50px;
  border-radius: 10%;

  h3 {
    margin: 0 auto;
    padding-bottom: 40px;
    font-family: Montserrat-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    color: #000000;
  }

  &__input {
    margin-bottom: 25px;
    width: 300px;
    border: solid 1px #4353ac;
    border-radius: 5px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
  }
}

.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.success-message {
  color: #3fcc69;
  font-size: 12px;
}

.change-button {
  margin-top: 25px;
}
.el-input__inner {
  text-align: center;
}
.el-button--primary {
  color: #fff;
  background: linear-gradient(90deg, #4356be -12.94%, #414769 100%), #414769;
  border-radius: 30px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
</style>
