import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home";
import PersonalAccount from "@/components/personalAccount";
import PersonalHome from "../components/personalAccount/home";
import PersonalDetails from "../components/personalAccount/details";
import PersonalTransactions from "../components/personalAccount/transactions";
import PersonalInternationalTransfer from "../components/personalAccount/internationalTransfer";
import PersonalIntraTransfer from "../components/personalAccount/intraTransfer";
import ApiGuide from "../components/apiGuide";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/api-guide",
    name: "apiGuide",
    component: ApiGuide,
  },
  {
    path: "/personal-account",
    name: "personalAccount",
    component: PersonalAccount,
    children: [
      {
        path: "",
        name: "home",
        component: PersonalHome,
      },
      {
        path: "details",
        name: "details",
        component: PersonalDetails,
      },
      {
        path: "transactions",
        name: "transactions",
        component: PersonalTransactions,
      },
      {
        path: "international-transfer",
        name: "internationalTransfer",
        component: PersonalInternationalTransfer,
      },
      {
        path: "intra-transfer",
        name: "intraTransfer",
        component: PersonalIntraTransfer,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
