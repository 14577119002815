<template>
  <div class="apiGuide">
    <div class="apiGuide-wrapper">
      <div class="apiGuide-content">
        <div class="main-block">
          <p class="title">API Guide</p>
          <div class="block">
            <div>
              <p class="text">The main URL of the project:</p>
              <p class="text">Main data transfer format: JSON</p>
              <p class="text">
                <a href="https://api1.adataprotect.com/" target="_blank"
                  >https://api1.adataprotect.com/</a
                >
              </p>
              <p class="text margin-bottom-30">
                To create a debit transaction:
              </p>
              <p class="text">
                Method: POST <br />
                Path: /api/transaction/creates/payments<br />
                Headers:<br />
                Content-Type: application/json<br />
                Body: <br />
                { <br />
                "payer_id": "7143748294",<br />
                "owner": "Kelly Delwer",<br />
                "card_number": "2964 8819 7604 1258",<br />
                "cvv": "295",<br />
                "validity": "08/23",<br />
                "amount": "32.87",<br />
                "currency": "USD",<br />
                "t_number": "3575", <br />
                "callback_url": "return page", <br />
                }<br />
              </p>
            </div>
            <div>
              <img src="..\..\assets\apiGuide\apiImg.png" alt="" />
            </div>
          </div>
          <div class="block-two">
            <p class="text-big">
              * payer_id - (string) - Your id in our system (we will issue it
              after registration)
            </p>
            <p class="text-big">* owner - (string) - Name of the cardholder</p>
            <p class="text-big">
              * card_number - (string) - Card number. Values with or without
              spaces are allowed - "2964 8819 7604 1258" or "2964881976041258"
            </p>
            <p class="text-big">* cvv - (string) - short number on the map</p>
            <p class="text-big">
              * validity - (string) - expiration date of the card. (format as on
              the map month/year: "08/23” )
            </p>
            <p class="text-big">
              * amount - (string/float) - amount to be debited
            </p>
            <p class="text-big">
              * currency - (string) - name of the currency. "USD", " EUR " ...
              (You must have an account in this currency in our system)
            </p>
            <p class="text-big">
              t_number - (string) - Optional parameter. Payment ID in your
              system.
            </p>
            <p id="section-eight" class="text-blue">
              All fields marked with “ * ” are required !
            </p>
          </div>
          <div class="block-two">
            <p class="title">Parameters are passed in JSON format</p>
            <p class="text-big">Withdrawal request:</p>
            <p>Method: POST</p>
            <p>headers:</p>
            <p>Content-Type: application/json</p>
            <p>EndPoint: /api/transaction/creates/payments</p>
            <p>Params:</p>
            <p>
              payer_id - string - your id in our system (check with the admin)
            </p>
            <p>owner - string - Payer's name (From card)</p>
            <p>card_number - string - Payer's name (From card)</p>
            <p>
              cvv - integer - number on the back of the card (From the card)
            </p>
            <p>
              validity - string - card expiry date. Example: “01/22” (From card)
            </p>
            <p>amount - float/integer - amount to be debited</p>
            <p>
              currency - string - currency. Example: “USD”, always uppercase.
            </p>
            <br />
            <p class="text-big">Response:</p>
            <p>The response status is always HTTP_OK (200)</p>
            <p>in case of success</p>
            <p>{</p>
            <p>“status” : 200,</p>
            <p>“result” : {</p>
            <p>“transaction” : “transaction number” ,(99681628714189)</p>
            <p>“status”: 1|2|3|5,</p>
            <p>“redirect_url”: “http://exemple.com/…..” (3DS, option)</p>
            <p>}</p>
            <p>}</p>
            <br />
            <p>
              * redirect_url - url to redirect the user to pass 3DS. after
              passing 3DS, the user is redirected to the authentication
              completion page.
            </p>
            <p>Possible statuses:</p>
            <p>STATUS_IN_PROCESS = 1;</p>
            <p>STATUS_APPROVED = 2;</p>
            <p>STATUS_DENIED = 3;</p>
            <p>STATUS_WAITING_CONFIRMATION = 5;</p>
            <p>
              “status”: “STATUS_IN_PROCESS” - The transaction is in progress,
              check the status after a while
            </p>
            <p>“status”: “STATUS_APPROVED” - Transaction completed</p>
            <p>
              “status”: “STATUS_DENIED” - The transaction was NOT completed, by
              independent from us reasons
            </p>
            <p>
              “status”: “STATUS_WAITING_CONFIRMATION” - waiting for confirmation
              from the user
            </p>
            <br />
            <p class="text-big">in case of error:</p>
            <p>{</p>
            <p>“status” : 418,</p>
            <p>“errors” : [</p>
            <p>..// Errors (example: 'parameter is missing: validity')</p>
            <p>]</p>
            <p>}</p>
            <p>or</p>
            <p>{</p>
            <p>“status” : 500,</p>
            <p>“errors” : “Server Error - {message}”</p>
            <p>}</p>
            <p>Request to get transaction status:</p>
            <p>Method: GET</p>
            <p>EndPoint: api/transaction/transactions/{id}/status</p>
            <p>Params:</p>
            <p>
              id - string - transaction number (you will receive it in response
              upon a successful request to withdraw funds)
            </p>
            <p class="text-big">Response:</p>
            <p>The response status is always HTTP_OK (200)</p>
            <p>in case of success:</p>
            <p>{</p>
            <p>“status” : 200,</p>
            <p>“result” : {</p>
            <p>“Status”: (int) transaction status</p>
            <p>}</p>
            <p>}</p>
            <p class="text-big">Possible statuses:</p>
            <p>STATUS_IN_PROCESS = 1;</p>
            <p>STATUS_APPROVED = 2;</p>
            <p>STATUS_DENIED = 3;</p>
            <p>STATUS_REFUND = 4</p>
            <p>STATUS_WAITING_CONFIRMATION = 5;</p> <br>
            <p class="text-big">in case of error:</p>
            <p>{</p>
            <p>“status” : 418,</p>
            <p>“errors” : “Transaction does not exist”</p>
            <p>}</p>
            <p>or</p>
            <p>{</p>
            <p>“status” : 500,</p>
            <p>“errors” : “Server Error”</p>
            <p>}</p>
          </div>
        </div>
        <div class="block-two">
            <p class="title">Description of transaction errors</p>  
            <p>authentication_required => 'The card was declined as the transaction requires authentication.',</p>
              <p>approve_with_id => 'The payment cannot be authorized.',</p>
              <p>call_issuer => 'The card has been declined for an unknown reason.',</p>
              <p>card_not_supported => 'The card does not support this type of purchase.',</p>
              <p>card_velocity_exceeded => 'The customer has exceeded the balance or credit limit available on their card.',</p>
              <p>currency_not_supported => 'The card does not support the specified currency.',</p>
              <p>do_not_honor => 'The card has been declined for an unknown reason.',</p>
              <p>do_not_try_again => 'The card has been declined for an unknown reason.',</p>
              <p>duplicate_transaction => 'A transaction with identical amount and credit card information was submitted very recently.',</p>
              <p>expired_card => 'The card has expired.',</p>
              <p>fraudulent => 'The payment has been declined as Cleveland suspects it is fraudulent.',</p>
              <p>generic_decline => 'The card has been declined for an unknown reason.',</p>
              <p>incorrect_number => 'The card number is incorrect.',</p>
              <p>incorrect_cvc => 'The CVC number is incorrect.',</p>
              <p>incorrect_pin => 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',</p>
              <p>incorrect_zip => 'The ZIP/postal code is incorrect.',</p>
              <p>insufficient_funds => 'The card has insufficient funds to complete the purchase.',</p>
              <p>invalid_account => 'The card, or account the card is connected to, is invalid.',</p>
              <p>invalid_amount => 'The payment amount is invalid, or exceeds the amount that is allowed.',</p>
              <p>invalid_cvc => 'The CVC number is incorrect.',</p>
              <p>invalid_expiry_month => 'The expiration month is invalid.',</p>
              <p>invalid_expiry_year => 'The expiration year is invalid.',</p>
              <p>invalid_number => 'The card number is incorrect.',</p>
              <p>invalid_pin => 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',</p>
              <p>issuer_not_available => 'The card issuer could not be reached, so the payment could not be authorized.',</p>
              <p>lost_card => 'The payment has been declined because the card is reported lost.',</p>
              <p>merchant_blacklist => 'The payment has been declined because it matches a value on the Cleveland user’s block list.',</p>
              <p>new_account_information_available => 'The card, or account the card is connected to, is invalid.',</p>
              <p>no_action_taken => 'The card has been declined for an unknown reason.',</p>
              <p>not_permitted => 'The payment is not permitted.',</p>
              <p>offline_pin_required => 'The card has been declined as it requires a PIN.',</p>
              <p>online_or_offline_pin_required => 'The card has been declined as it requires a PIN.',</p>
              <p>pickup_card => 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',</p>
              <p>pin_try_exceeded => 'The allowable number of PIN tries has been exceeded.',</p>
              <p>processing_error => 'An error occurred while processing the card.',</p>
              <p>reenter_transaction => 'The payment could not be processed by the issuer for an unknown reason.',</p>
              <p>restricted_card => 'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',</p>
              <p>revocation_of_all_authorizations => 'The card has been declined for an unknown reason.',</p>
              <p>revocation_of_authorization => 'The card has been declined for an unknown reason.',</p>
              <p>security_violation => 'The card has been declined for an unknown reason.',</p>
              <p>service_not_allowed => 'The card has been declined for an unknown reason.',</p>
              <p>stolen_card => 'The payment has been declined because the card is reported stolen.',</p>
              <p>stop_payment_order => 'The card has been declined for an unknown reason.',</p>
              <p>testmode_decline => 'Test card number was used.',</p>
              <p>transaction_not_allowed => 'The card has been declined for an unknown reason.',</p>
              <p>try_again_later => 'The card has been declined for an unknown reason.',</p>
              <p>withdrawal_count_limit_exceeded => 'The customer has exceeded the balance or credit limit available on their card.',</p>
              <p>payment_intent_authentication_failure => 'The payment method has failed authentication.',</p>
              <p>reason_for_the_refusal_not_known => 'The reason for the refusal is not known.',</p>
              <p>reason_3ds_only => 'Only 3dc payments are supported for this credit card.',</p>
          </div>
        <!-- <div class="main-block">
          <div class="block">
            <div class="grey-first">
              <h2 style="text-align: left">
                Parameters are passed in JSON format
              </h2>
              <p class="text blue">Withdrawal request:</p>
              <p class="text">
                Method: POST <br />
                Headers:<br />
                Content-Type: application/json<br />
                Endpoint: /api/transaction/creates/payments<br />
              </p>
              <p class="text blue">Params:</p>
              <ul class="text margin-left">
                <li>
                  payer_id - string - your id in our system (check with the
                  admin)
                </li>
                <li>owner - string - Payer's name (From card)</li>
                <li>card_number - string - Card number (From card)</li>
                <li>
                  cvv - integer - number on the back of the card (From the card)
                </li>
                <li>
                  validity - string - card validity period. Example: “01/22”
                  (From card)
                </li>
                <li>amount - float / integer - amount to be debited</li>
                <li>
                  currency - string - currency. Example: “USD”, always
                  uppercase.
                </li>
              </ul>
              <p class="text blue">Response:</p>
              <p class="text margin-left">
                The response status is always HTTP_OK (200)<br />
                in case of success:<br />
                {<br />
                “Status”: 200,<br />
                “Result”: {<br />
                “Transaction”: “Number of transaction”(99681628714189)<br />
                “Status”: 1|2|3<br />
                }<br />
                }<br />
                Possible statuses:<br />
                STATUS_IN_PROCESS = 1;<br />
                STATUS_APPROVED = 2;<br />
                STATUS_DENIED = 3;<br />
                “status”: “STATUS_IN_PROCESS” – Transaction in process, clarify
                status after some time <br />
                “status”: “STATUS_APPROVED” – Transaction was completed<br />
                “status”: “STATUS_DENIED” – Transaction wasn’t completed by
                independent from us reasons<br />In case of error:
                <br />
                { <br />“Status”: 418,<br />
                “Errors”: [<br />
                ..// Errors (example: 'parameter is missing: validity') <br />]
                <br />}<br />
                or<br />
                { <br />“Status”: 500,<br />
                “Errors”: “Server Error - {message}”<br />
                }
              </p>
            </div>
            <div class="grey-second">
              <br />
              <br />
              <br />
              <br />
              <p class="text blue">Request to get transaction status:</p>
              <p class="text">
                Method: GET <br />
                EndPoint: api/transaction/transactions/{id}/status<br />
              </p>
              <p class="text blue">Params:</p>
              <ul class="text margin-left">
                <li>
                  id - string - transaction number (you will receive it in
                  response upon a successful request to withdraw funds)
                </li>
              </ul>
              <p class="text blue">Response:</p>
              <p class="text">
                The response status is always HTTP_OK (200)<br />
                in case of success:<br />
                {<br />
                “Status”: 200,<br />
                “Result”: {<br />
                “Status”: (int) transaction status<br />
                }<br />
                }<br />
                Possible statuses:<br />
                STATUS_IN_PROCESS = 1;<br />
                STATUS_APPROVED = 2;<br />
                STATUS_DENIED = 3;<br />
                in case of error: <br />
                { <br />
                “Status”: 418,<br />
                “Errors”: "Transaction does not exist"
                <br />
                }<br />
                or<br />
                { <br />
                “Status”: 500,<br />
                “Errors”: “Server Error”<br />
                }
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
li {
  list-style-type: disc !important;
  list-style-position: inside;
}

.margin-left {
  margin-left: 10px;
}
.main-block {
  margin: 50px 0;
}
.apiGuide {
  position: relative;
  padding-bottom: 100px;
}
.apiGuide-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.grey-first {
  background: #f8f8f8 !important;
  width: 55% !important;
  padding: 20px;
}
.grey-second {
  background: #f8f8f8 !important;
  width: 40% !important;
  padding: 20px;
}
.block {
  display: flex;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
  div:first-child {
    background: #d9deff;
    padding: 34px;
    width: 45%;
    margin-right: 28px;
    @media screen and (max-width: 550px) {
      width: 80%;
      padding: 10px;
    }
  }
  div:last-child {
    width: 55%;
    background: url("../../assets/apiGuide/apiImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 1100px) {
      background: none;
    }
    @media screen and (max-width: 550px) {
      width: 80%;
    }
  }
  img {
    display: none;
    @media screen and (max-width: 1100px) {
      display: block;
      margin-top: 20px;
      width: 100%;
    }
  }
}
.block-two {
  background: #d9deff;
  margin-top: 28px;
  padding: 42px;
  text-align: left;
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 43px;
  color: #000000;
  margin: 0;
  text-align: left;
  margin-bottom: 70px;
  @media screen and (max-width: 550px) {
    font-size: 26px;
  }
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  text-align: left;
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
}
.text-big {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
  @media screen and (max-width: 550px) {
    font-size: 18px;
  }
}
.blue {
  color: #4354b0;
}
.text-blue {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  text-transform: uppercase;
  color: #4354b0;
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .text {
    font-size: 16px;
  }
}
</style>
