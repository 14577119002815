<template>
  <div class="international-transfer-wrapper">
    <h2>International transfers</h2>
    <p class="description">
      Our global network of banks allows us to transfer money overseas securely
      and quickly. Most transfers may arrive within 1-3 days.
    </p>
    <div class="content">
      <h2>Beneficiary Bank details</h2>
      <div class="fields">
        <el-input
          class="fields__input"
          placeholder="SWIFT CODE"
          v-model="swiftCode"
        />
        <el-input
          class="fields__input"
          placeholder="Bank Name"
          v-model="bankName"
        />
      </div>
      <div class="fields">
        <el-input
          class="fields__input"
          placeholder="Beneficiary Address"
          v-model="benefAddress"
        />
        <el-input
          class="fields__input"
          placeholder="Beneficiary Name"
          v-model="benefName"
        />
      </div>
      <div class="fields">
        <el-input
          class="fields__input"
          placeholder="Account number:"
          v-model="iban"
        />
        <el-input
          class="fields__input"
          placeholder="Bank Address"
          v-model="bankAddress"
        />
      </div>
      <h2>Transfer details:</h2>
      <!-- <div class="fields">
      <el-input class="fields__input" placeholder="Beneficiary Country" v-model="benefCountry"/>
      <el-input class="fields__input" placeholder="Beneficiary City" v-model="benefCity"/>
    </div> -->
      <div class="fields">
        <el-select
          class="fields__select"
          v-model="selectedAccount"
          filterable
          placeholder="Account number"
        >
          <el-option
            v-for="item in accountDetails"
            :key="item.id"
            :label="
              `${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`
            "
            :value="item.account_special_number"
          >
          </el-option>
        </el-select>
        <el-input
          class="fields__input"
          placeholder="Amount"
          v-model="amount"
          type="number"
        />
      </div>
      <h2>Other details</h2>
      <div class="fields">
        <el-input
          class="fields__input"
          placeholder="Purpose of transfer:"
          v-model="benefReference"
        />
      </div>
      <p v-if="showErrorMessage" class="errorMessage">
        All fields must be filled
      </p>
      <!-- <el-radio-group v-model="radio"> -->
      <!-- <el-radio v-for="radio in radioButtons" :key="radio" :label="radio">
        {{ radio }}
      </el-radio> -->
      <!-- </el-radio-group> -->
      <div class="btn-block">
        <el-checkbox v-model="checked"
          >I agree with Cleveland Terms and Conditions</el-checkbox
        >
        <el-button
          type="primary"
          :disabled="!checked"
          @click="sendTransfer"
          :loading="loadingSubmitButton"
          >Send
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radioButtons: [
        "Sender to pay all charges (OUR)",
        "Beneficiary to pay all charges (BEN)",
        "Shared (SHA)",
      ],
      selectedAccount: null,
      amount: null,
      bankName: null,
      bankAddress: null,
      swiftCode: null,
      iban: null,
      benefCountry: null,
      benefCity: null,
      benefAddress: null,
      benefReference: null,
      benefName: null,
      radio: null,
      account: null,
      checked: false,
      showErrorMessage: false,
      loadingSubmitButton: false,
    };
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
      if (!this.$store.getters.user) {
        this.$router.push("/");
      }
    }
  },

  methods: {
    async sendTransfer() {
      const fields = [
        "amount",
        "bankName",
        "bankAddress",
        "swiftCode",
        "iban",
        "benefAddress",
        "benefReference",
        "selectedAccount",
        "benefName",
      ];
      let isValidForm = true;
      fields.forEach((item) => {
        if (!this[item]) {
          isValidForm = false;
          this.showErrorMessage = true;
        }
      });
      if (!isValidForm) return;
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendInterTransfer", {
          Amount: this.amount,
          "Bank Name": this.bankName,
          "Bank Address": this.bankAddress,
          "SWIFT Code": this.swiftCode,
          "IBAN/Beneficiary Account Number": this.iban,
          "from-account": this.selectedAccount,
          "Beneficiary Address": this.benefAddress,
          "Beneficiary Reference": this.benefReference,
          "Beneficiary Name": this.benefName,
        })
        .then(() => {
          fields.forEach((item) => {
            this[item] = null;
            this.checked = false;
            this.radio = 0;
          });

          this.loadingSubmitButton = false;
          this.showErrorMessage = false;
        });
    },
  },

  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.international-transfer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.description {
  width: 36%;
  text-align: left;
}

.fields {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  flex-direction: column;
  height: 100px;
  &__select {
    width: 300px;
    margin-bottom: 25px;
  }

  &__input {
    width: 300px;
    margin-bottom: 25px;
  }
}

.el-radio-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.el-radio {
  margin-bottom: 15px;
}

.el-checkbox {
  margin-bottom: 15px;
}

.errorMessage {
  color: #cc3f55;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #fff;
  background: linear-gradient(90deg, #4356be -12.94%, #414769 100%), #414769;
  border-radius: 30px;
  width: 50%;
  margin: 0 auto;
}
.btn-block {
  display: flex;
  flex-direction: column;
}
</style>
