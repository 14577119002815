export default {
  text: 'Open an Account',
  text1: 'Services',
  text2: 'Corporate',
  text3: 'Contact',
  text4: 'E-banking',
  text5: 'Registration',
  text6: 'CLEVELAND BANK',
  text7: 'CLEVELAND BANK – THE BANK YOU CAN TRUST',
  text8: 'Contact Us',
  text9: 'CHECK YOUR BALANCE, DO TRANSACTIONS, GET BENEFITS WITHOUT LEAVING YOUR HOME',
  text10: 'Sign In',
  text11: 'DIRECT INTERNATIONAL IBAN',
  text12: 'Special IBAN is designed for those customers whose professional interest is spread all over the world. This allows you to work around the world in accordance with the international payment system.',
  text13: 'It lets you get money from different parts of the world.',
  text14: 'The minimum deposit is required to be 5,000 euros.',
  text15: 'This type of account takes 14-21 banking days.',
  text16: 'PERSONALIZED ASSISTANCE',
  text17: 'Our high-qualified specialist will always help you and provide with all necessary information. You will get his number. You can be given the assistance any time you need to.',
  text18: 'FAST ACCOUNT OPENING',
  text19: 'Your account will be created at a high-technology platform with maximum speed',
  text20: 'Contact Us',
  text21: 'OUR BENEFITS:',
  text22: 'There is no CRS: Common Reporting Standard (CRS) is an information standard for automatic information exchange (AEOI) regarding global bank accounts between tax authorities. Please be aware that the Western Sahara is disputed territory and is not CRS. Compliant therefore you can be sure that your personal information will be safe.',
  text23: 'You get high privacy and security: our system is fully secured by a network for the transfer of private and corporate information, ensuring that business operations are carried out without delay.',
  text24: 'NO ROGATORY: “Searching for assets using Rogatory letters” reports that the national court may issue a letter form (a.k.a. “letter of inquiry” or “request for legal assistance”) to try to force a foreign bank to disclose offshore records.',
  text25: 'ABOUT CLEVELAND BANK',
  text26: 'High-qualified workers of our bank are always here to provide you with the best service. The accounts of Cleveland Bank are most secured. Keep money safe and leave the client satisfy are the main goals of Cleveland Bank.',
  text27: 'Learn more',
  text28: '100% Personal Satisfaction',
  text29: 'Highly Secured Systems',
  text30: 'Dedicated support(24/7)',
  text31: 'You Can Rely On Us',
  text32: 'Setup in Jurisdictions Worldwide',
  text33: 'Open an Account',
  text34: 'Services',
  text35: 'Corporate',
  text36: 'Contact',
  text37: 'Phone: +256206300956',
  text38: 'eMail:  info@clevelandinterbank.com',
  text39: 'Banking License Number: 159 20/ CIBL Central Reserve Authority of SADR',
  text40: 'Cleveland Bank © 2020 / All Rights Reserved',
  text41: 'CLEVELAND BANK WAS CREATED TO WORK WITH ACCOUNTS OF BOTH CORPORATE CLIENTS AND PRIVATE INDIVIDUALS',
  text42: 'HOW TO OPEN AN ACCOUNT:',
  text43: 'step',
  text44: 'You need to send to your personal manager the scan of all necessary documents, required for opening the account.',
  text45: 'You are waiting for 3-5 days to let Compliance Department to check the documents.',
  text46: 'step',
  text47: 'step',
  text48: 'You get the account opening letter by e-mail, where you can find online banking keys.',
  text49: 'The account is totally prepared for work.',
  text50: 'step',
  text51: '*Sub-accounts in currencies other than EUR can now be opened upon client’s request',
  text52: 'Contact Us',
  text53: 'OUR CLIENTS',
  text54: 'Our corporate bank accounts offer everything you can expect from an international private bank. Your personal banker will keep track of all new accounts, at any time you are always welcome to call him.',
  text55: '— Anna Shnikker, account manager',
  text56: 'Our benefits',
  text57: 'Our bank is sensitive to market demands and strives to maintain new “growth points” with its presence. And, of course, we are making new plans. They are quite ambitious, but have a solid foundation, which consists of clarity of goals, knowledge of methods for achieving them and cohesion of the whole team.',
  text58: 'We overcame many difficulties, but in any situation, the customer was always the main value for us, and his trust was the highest achievement. This is the greatest value on which the success of each bank is built.',
  text59: 'We grow with customers. We increase our financial literacy, offer products and services that are convenient and in demand by them, and make individual decisions.',
  text60: 'We are constantly improving banking services and products, but one thing remains unchanged - responsiveness, honesty and reliability.',
  text61: 'Our personal managers are always ready to solve any problem you come across with.',
  text62: 'We are experienced and experts. We are technology and modernity. We are security and trustiness.',
  text63: 'MONEY TRANSFERS',
  text64: 'You can transfer money worldwide. It takes 1-3 business days. We have chosen the fastest and most reliable money transfer routes. In case of large transfers of money, you should notify your personal manager.',
  text65: 'CASH DEPOSIT',
  text66: 'Collection of cash takes place in the most convenient place for the client. It is possible to use an ATM.',
  text67: 'CRYPTO',
  text68: 'Cryptocurrency is a contemporary form of private money. Thanks to its technological property, its global transaction networks are relatively safe, transparent, and fast. This gives it good prospects for further development. As with other innovations, cryptocurrency pose a challenge to financial regulators, in particular because of their anonymity and trans-border character.',
  text69: 'There are several different ways to add crypto exposure to your investment portfolio. From buying actual cryptocurrency to purchasing bitcoin mining stocks or blockchain, there is seemingly something for every type of investor.',
  text70: 'If you want to start investing in cryptocurrency, you can purchase your first crypto by opening an account in Cleveland Bank. Receive, store, send, buy, sell and use cryptocurrencies and fiat currency easily, quickly and safely.',
  text71: '',
  text72: '',
  text73: 'CHEQUE CLEARANCE',
  text74: 'The time of this operation varies from 10 to 40 banking days.',
  text75: 'Contact Us',
  text76: 'INVESTMENT',
  text77: 'Debit Cards, Saving accounts, Online Banking, Cryptocurrency with other services are available with Cleveland Bank. Please reach us to receive more information',
  text78: 'Contact Us',
  text79: 'CORPORATE',
  text80: 'Cleveland International Bank has been serving its clients for many years now, and has had a wonderful track record for its services rendered till date. It has strived to follow all the rules and regulations set forth by the governing authorities of the country which has given its clients a safe banking experience along with all the features and benefits as an offshore bank.',
  text81: 'The Bank has been currently operating with the license provided by The Northern Cyprus of the Turkish Republic. However, to enhance security and improve services for its client, Cleveland International Bank needed to look for an alternative jurisdiction. After a careful search and researching the best alternatives for carrying out all offshore banking operations, it was decided and necessary to obtain a license to continue the banks uninterrupted operations due to which it looked to shift its legal entity under the new license obtained from the jurisdiction of the Western Sahara.',
  text82: 'Cleveland International Bank now enjoys all the benefits of a legal bank and can entertain its clientele without any hindrances under the new jurisdiction.',
  text83: '',
  text84: 'Our experienced and talented teams of corporate banking professionals provide a wide range of financing solutions to meet the needs of any company. We also offer a comprehensive suite of management solutions to help you manage your cash flow more effectively.',
  text85: 'Industries',
  text86: 'Customer policy availability',
  text87: 'Activities to constantly attract new customers',
  text88: 'Availability of competitive business technologies for working with clients in the workplace',
  text89: 'Conducting ongoing assessments of the quality of customer service through surveys and monitoring',
  text90: 'Customer base segmentation',
  text91: 'Development of mutually beneficial relationships with customers by assisting customers in their commercial and other activities',
  text92: 'Cleveland Bank specializes in arrangement of different kind of businesses even high-risk ones as well offshore jurisdictions. With our network of offshore and domestic banking partners, we are able to provide a solution for your business even if you are from a high risk business, or you are facing difficulties to open a bank account. You can be sure wherever we provide support, we will take the time to understand your goals and needs to ensure your business capital works as hard as possible.',
  text93: 'Long-term partnership relationships with our customers and partners are the best evidence for successful cooperation. ',
  text94: 'Privacy Policy',
  text95: 'We recognize, respect and protect the personal privacy rights of all Cleveland Bank clients. Cleveland Bank realizes that you entrust us with personal information, and it is our policy to maintain that information in a confidential manner. We are committed to providing the highest level of security and privacy regarding the collection and use of your personal information. ',
  text96: 'Information we receive from you on applications or other forms',
  text97: 'Information about your transactions with us.',
  text98: 'Our bank recommends installing and regularly updating anti-virus software on your devices: computers, laptops, tablets, phones.',
  text99: 'When receiving suspicious letters, pay attention to the email address of the sender of the letter. If in doubt, call our contact center.',
  text100: 'If you have such an opportunity - get a separate card specifically for payments on the Internet, set a small transaction limit on it and deposit money into your account only for the scheduled operation.',
  text101: 'It is recommended to connect the SMS-notification service and 3D Secure service to the card. The prompt receipt by the client of information about transactions carried out using a bank payment card significantly reduces the risk of fraudulent transactions on it and allows you to control any expense transactions with a bank payment card in real time.',
  text102: 'Human Intervention',
  text103: 'The specialists of our bank always checks all accounts. The hand-made checking lets provide the highest secure and privacy of the clients.',
  text104: 'Industry Leading Encryption',
  text105: 'Our IT-specialists installed the proper software, which totally keep safe all the accounts.',
  text106: 'Transactional Only Banking',
  text107: 'Not everyone knows, that the most popular way to be fraud is while doing online transactions and the fractional reserve banking system. You can be calm, because The Cleveland bank has a 100% deposit ratio.',
  text108: 'Contact Us',
  text109: 'Name',
  text110: 'E-Mail',
  text111: 'Phone',
  text112: 'Company Name',
  text113: 'Country',
  text114: 'Message',
  text115: 'Send',
  text116: 'OUR OFFICES',
  text117: 'Our representative offices',
  text118: 'LETISIA TOWER, Plot 59C,',
  text119: 'KIJITONYAMA, NEW BAGAMOYO ROAD, DAR ES SALAAM, TANZANIA (Mon-Fri, 10.00 am till 7.00 pm)',
  text120: 'To enter online banking write your ID and password',
  text121: 'Login',
  text122: 'Cancel',
  text123: 'To apply for Bank Account, please fill in the application form below',
  text124: 'First Name',
  text125: 'Last Name',
  text126: 'Country',
  text127: 'City',
  text128: 'Address',
  text129: 'Post code',
  text130: 'Phone Number',
  text131: 'E-mail',
  text132: 'Password',
  text133: 'Confirm password',
  text134: 'Send',
  text135: 'Cancel',
  text136: 'Fees',
  text137: 'Fees and conditions',
  text138: 'We aim to keep our rates and charges clear and easy to understand, so its easier for you to draw comparisons as well as make well-informed decisions when it comes to your money.',
  text139: 'To know more, get in touch!',
  text140: 'The bank collects nonpublic personal information about you from the following sources:',
  text141: 'Cookies Policy',
  text142: 'Cookies are text files containing small amounts of information which are downloaded to visitor’s computer or device when he/she visits Bank’s website or internet-enabled service. Cookies are then sent back to the originating web domain on visitor’s subsequent visits to that domain.',
  text143: 'Cookies are useful because they allow a website or internet-enabled service to recognize a user’s device. Cookies allow the visitor to navigate between pages efficiently, remember preferences and generally improve the user experience and for the Bank to make the banking services more secure. Session cookies are deleted automatically when the user closes the browser and persistent cookies remain on the device after the browser is closed (for example to remember user’s preferences when he/she returns to the site or service). The Bank also uses cookies as help to compile aggregate data about site traffic and site interaction so that the Bank can offer better site experiences and tools in the future.',
  text144: 'By visiting Bank’s webpage or by using the Internet Banking the visitors/clients consent to the use of the cookies set out in this Policy. If the client does not consent to the use of the cookies he/she should not browse the Bank’s webpage, should not access Bank’s internet banking.',
}
