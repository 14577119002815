<template>
  <div class="ourBenefits">
    <div class="ourBenefits-wrapper">
      <div class="ourBenefits-content d-flex-rw">
        <div class="icon-section">
          <div class="ourBenefits-title-block">
            <h1 class="ourBenefits-title">Our benefits</h1>
          </div>
          <div class="text-block text e-banking-icon">
            <span class="icon-title">There is no CRS </span>
            <span
              >Common Reporting Standard (CRS) is an information standard for
              automatic information exchange (AEOI) regarding global bank
              accounts between tax authorities. Please be aware that the Western
              Sahara is disputed territory and is not CRS. Compliant therefore
              you can be sure that your personal information will be safe.
            </span>
          </div>
          <div class="text-block text persona-icon mar-left-170">
            <span class="icon-title">DIRECT INTERNATIONAL IBAN</span>
            <span
              >Special IBAN is designed for those customers whose
              professional interest is spread all over the world. This allows
              you to work around the world in accordance with the international payment
              system. It lets you to get money from different parts of the
              world.</span
            >
            <ul>
              <li>The minimum deposit is required to be 5,000 euros;</li>
              <li>This type of account takes 14-21 banking days.</li>
            </ul>
          </div>
          <div class="text-block account-icon text">
            <span class="icon-title">You get high privacy and security</span>
            <span>
              our system is fully secured by a network for the transfer of
              private and corporate information, ensuring that business
              operations are carried out without delay.
            </span>
          </div>
          <div class="text-block text valeus-icon mar-left-170">
            <span class="icon-title">NO ROGATORY</span>
            <span id="section-three"
              >“Searching for assets using Rogatory letters” reports that the
              national court may issue a letter form (a.k.a. “letter of inquiry”
              or “request for legal assistance”) to try to force a foreign bank
              to disclose offshore records.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ourBenefits {
  background: #d9deff;
  position: relative;
  z-index: -111;
}
.ourBenefits-wrapper {
  max-width: 1170px;
  margin: 0 auto;

  .ourBenefits-title-block {
    width: 100%;
    text-align: left;
    margin-bottom: 100px;

    .ourBenefits-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 43px;
      text-transform: uppercase;
      color: #000000;
      text-align: left;
    }
  }
  .title {
    font-weight: 500;
    font-size: 35px;
    line-height: 150%;
    color: #000000;
    display: block;
    text-align: left;
    width: 95%;
  }
  .icon-title {
    padding-bottom: 30px;
  }
  ul {
    padding-top: 15px;
    li {
      padding-bottom: 10px;
      margin-left: 30px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -30px;
        width: 17px;
        height: 17px;
        background: url("../../assets/about/check.svg");
      }
    }
  }
  .text {
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
  }
  .ourBenefits-open-block {
    width: 40%;
    margin-right: 93px;
  }
  .ourBenefits-info-block {
    width: 60%;
  }
  .ourBenefits-paragraph {
    margin-bottom: 50px;
  }
  .mar-left-170 {
    margin-left: 170px;
  }
  .btn-more {
    border: none;
    background: none;
    cursor: pointer;
    padding-right: 70px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 8px;
      right: 20px;
      width: 30px;
      height: 4px;
      background: url("../../assets/about/arrow.svg");
    }
  }
  @media screen and (max-width: 1200px) {
    margin-top: 10px;
    width: 90%;
    padding: 30px 10px;

    .title {
      margin-bottom: 10px;
    }
    .mar-left-170 {
      margin-left: 0;
    }
  }
  .text-block {
    max-width: 450px;
    margin-bottom: 84px;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
  }
  .icon-section {
    justify-content: center !important;
  }
}
.icon-section {
  margin-top: 104px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.e-banking-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 150px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/ourBenefits/exchange.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.persona-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/ourBenefits/economy.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.account-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/ourBenefits/xmlid.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.valeus-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -35px;
    left: -100px;
    width: 165px;
    height: 165px;
    background: url("../../assets/ourBenefits/investment.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -100;
  }
}
.button-container {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: 50px;
}

@media screen and (max-width: 1200px) {
  .text {
    font-size: 18px !important;
  }

  .text-block {
    padding: 20px;
  }
  .e-banking-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .persona-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .account-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
  .valeus-icon {
    &::before {
      top: -35px;
      left: 0px;
      width: 100px;
      height: 100px;
    }
  }
}
</style>
